import React from 'react';
import ReactDOM from "react-dom/client";
import App from "./App";
import "../src/assets/css/venjos.css";

import { BrowserRouter, Routes, Route} from "react-router-dom";

import Dashboard from "./components/dashboard/Dashboard";
import NoPage from "./components/pages/NoPage";
import Login from "./components/login/Login";
import Logout from "./components/login/logout";
import LoadPlanRoute  from './components/loadsummary/plantRouteComponent'
import LoadPlanScreen  from './components/loadsummary/loadPlanSummaryComponent'
import LoadPlanSummary  from './components/loadsummary/loadPlanSummaryGrid'
import Dealers  from './components/dealers/dealerComponent'
import DealerParts  from './components/dealers/dealerpartsComponent'
import SummaryDashboard  from './components/dashboard/summaryDashboard'
import ClusterRoute  from './components/loadsummary/clusterrouteComponent'
import VehicleDetails  from './components/loadsummary/vehicledetailgrid'
// import BinPacking  from './components/loadsummary/binPacking'
import Packing  from './components/loadsummary/packingComponent'
import StackingPlanSummary  from './components/loadsummary/stackingplantsummary'
import VehicleInfo  from './components/loadsummary/vehicleinformation'
import InputPlan  from './components/loadsummary/inputplandata'
import Packing3D  from './components/loadsummary/3dpackingComponent'
import PreviousPacking3D  from './components/loadsummary/previous3dstacking'
import PreviousPlans  from './components/loadsummary/previousstackingplansummary'
import Configurations from './components/optirun-admin/configurations'
import OptiRunAdmin from './components/optirun-admin/optirunComponent'

import PickupDropLocations from './components/optirun-admin/pickupdroplocationsComponent'
import VehiclesData from './components/optirun-admin/vehiclesMasterData'
// import VehicleAvailability from './components/optirun-admin/vehicleAvailability'
// import TransporterDetails from './components/optirun-admin/transporterDetails'
import MaterialConfigurations from './components/optirun-admin/materialConfigurations'
import RouteConfigurations from './components/optirun-admin/routeConfigurations'
import AutoClustering from './components/optirun-admin/autoclustering'
import TransporterShareOfBusiness from './components/optirun-admin/transportershareofbusiness'
// import FreightConfigurations from './components/optirun-admin/freightConfigurations'
import LoadProcessRun  from './components/optirun-admin/loadprocessrun'
import PlanNewDispatch  from './components/optirun-admin/plannewdispatch.js'
import DipatchPlanHistory  from './components/optirun-admin/dispatchplanhistory'
import ViewDispatchPlanDetails  from './components/optirun-admin/viewdispatchplandetails'

import VehicleCostConfigurations from './components/optirun-admin/vehicleCostComponent'
// import ZoneMapping from './components/optirun-admin/zonemapping'

import PlantWarehouseLocations from './components/stacking-plan/plantOrWarehouseLocations'
import StackingVehicleDetails from './components/stacking-plan/stackingVehicleDetails'
import MaterialDetails from './components/stacking-plan/stackingMaterialDetails'
// import ForecastInputData from './components/forecast/forecastInputdata'
import ForecastInputData from './components/forecast/forecastInputdataNew'
import ForecastTriggerLearning from './components/forecast/forecasttriggerlearning.js'
import ForecastOutputParams from './components/forecast/forecastoutputparams'
import ForecastOutputData from './components/forecast/forecastOutputdata'
import ForecastCustomers from './components/forecast/forecastCustomers'
import ForecastMaterials from './components/forecast/forecastMaterials'
import ForecastPreviousPlan from "./components/forecast/forecast-previousplan"
import ForecastSuppliers from "./components/forecast/forecastSuppliers"
import VehicleAvailabilityDetails from "./components/optirun-admin/vehicleAvailabilityDetails"
import TransporterConfiguration from "./components/optirun-admin/transporterConfigurations"
import ZoneConfiguration from "./components/optirun-admin/zoneConfiguration"
import PredictionAnalysis from "./components/forecast/predictionAnalysis"

import ForecastResultAnalysis from './components/forecast/forecastResultAnalysis';
import DiscussionBox from "./components/discussion/discussionbox";
import OverallSummaryDashboard from './components/dashboard/overallSummaryDashboard';
import AnamolyForecastOutputParams from './components/forecast/anamolyForecast'
import NewMaterialAnalysis from './components/forecast/newmaterialanalysis'
import ForecastDashboard from './components/forecast/forecastDashboard'
import ResultsSummary from './components/forecast/resultSummary';
import ChangePassword from './components/dashboard/changepasswordcomponent';
import NewChart from "./components/forecast/newchart"
import UnderPredictionAnalysis from './components/forecast/underpredictionanalysis';
import EnmUserLogin from './components/login/enmuserlogin.js';
import BusinessDashboard from './components/forecast/businessdashboard.js';
import CopyOverallSummaryDashboard from './components/dashboard/copyoverallsummarydashboard.js';
export default function Root() {
  return (
    <BrowserRouter>
      <Routes>
	  
        <Route path="/login" element={<Login />} />
        <Route path={`/enmuserlogin/:token`} element={<EnmUserLogin />} />
        <Route path="/logout" element={<Logout />} />
        <Route path={"/packing3d/:rowid"} element={<Packing3D />} />
        <Route path={"/previouspacking3d/:rowid"} element={<PreviousPacking3D />} />
        <Route path={"/packing/:rowid"} element={<Packing />} />
                       
        <Route path="/" element={<App />}>
          <Route path="/business-dashboard" element={<BusinessDashboard />} />
          <Route path="/dashboard" element={<CopyOverallSummaryDashboard />} />
          <Route exact path="/" element={<OverallSummaryDashboard />} />
          <Route path="/stackplan" element={<StackingPlanSummary />} />
          <Route path={"/vehicleinfo"} element={<VehicleInfo />} />
          <Route path={"/inputplan"} element={<InputPlan />} />
          <Route path={"/previousplans"} element={<PreviousPlans />} />
          {/* <Route path={"/binpacking"} element={<BinPacking />} /> */}
          <Route path={"/loadplan"} element={<LoadPlanRoute />} />
          <Route path={"/suppliers"} element={<Dealers />} />
          <Route path={"/partsdetails"} element={<DealerParts />} />
          <Route path={"/summarydashboard"} element={<SummaryDashboard />} />
          <Route path={"/loadplanroute"} element={<LoadPlanScreen />} />
          <Route path={"/loadplansummary"} element={<LoadPlanSummary />} />
          <Route path={"/clusterplan"} element={<ClusterRoute />} />
          <Route path={"/vehicledetails"} element={<VehicleDetails />} />
        <Route path={"/packing3dnew"} element={<Packing3D />} />

          


          <Route path={"/configurations"} element={<Configurations />} />
          <Route path={"/configurations?dispatch_planning"} element={<Configurations />} />
          <Route path={"/configurations?stacking"} element={<Configurations />} />
          <Route path={"/optirunadmin"} element={<OptiRunAdmin />} />
          {/* DISPATCH PLANNING */}
          <Route path={"/pickup_and_drop"} element={<PickupDropLocations />} />
          <Route path={"/vehicles_data"} element={<VehiclesData />} />
          {/* <Route path={"/vehicle_availability"} element={<VehicleAvailability />} /> */}
          <Route path={"/vehicle_availability"} element={<VehicleAvailabilityDetails />} />
          {/* <Route path={"/transporter_details"} element={<TransporterDetails />} /> */}
          <Route path={"/transporter_details"} element={<TransporterConfiguration />} /> 
          <Route path={"/materialconfigurations"} element={<MaterialConfigurations />} />
          <Route path={"/routeconfigurations"} element={<RouteConfigurations />} />
          <Route path={"/autoclustering"} element={<AutoClustering />} />
          <Route path={"/transportershareofbusiness"} element={<TransporterShareOfBusiness />} />
          <Route path={"/freightconfigurations"} element={<VehicleCostConfigurations />} />
          <Route path={"/loadprocessrun"} element={<LoadProcessRun />} />
          <Route path={"/plannewdispatch"} element={<PlanNewDispatch />} />
          <Route path={"/dipatchplanhistory"} element={<DipatchPlanHistory />} />
          <Route path={"/viewdispatchplandetails"} element={<ViewDispatchPlanDetails />} />
          <Route path={"/freightconfigurations"} element={<VehicleCostConfigurations />} />
          {/* <Route path={"/zonemapping"} element={<ZoneMapping />} /> */}
          <Route path={"/zonemapping"} element={<ZoneConfiguration />} />
          <Route path={"/discussions"} element={<DiscussionBox />} />
          <Route path={"/forecast-dashboard"} element={<ForecastDashboard />} />

          {/* <Route path={"/vehiclecostconfigurations"} element={<VehicleCostConfigurations />} /> */}

          {/* STACKING PLAN */}
          <Route path={"/plantOrWarehouse"} element={<PlantWarehouseLocations />} />
          <Route path={"/stacking_vehicles"} element={<StackingVehicleDetails />} />
          <Route path={"/stackingmaterial_details"} element={<MaterialDetails />} />
          <Route path={"/forecast-input"} element={<ForecastInputData />} />
          <Route path={"/forecast-trigger-learning"} element={<ForecastTriggerLearning />} />
          <Route path={"/forecast-predict"} element={<ForecastOutputParams />} />
          <Route path={"/forecast-customers"} element={<ForecastCustomers />} />
          <Route path={"/forecast-materials"} element={<ForecastMaterials />} />
          <Route path={"/forecast-output"} element={<ForecastOutputData />} />
          <Route path={"/forecast-prev-output"} element={<ForecastOutputData />} />
          <Route path={"/forecast-previous"} element={<ForecastPreviousPlan />} />
          <Route path={"/forecast-suppliers"} element={<ForecastSuppliers />} />
          <Route path={"/predictionanalysis"} element={<PredictionAnalysis />} />
          <Route path={"/forecastresult_analysis"} element={<ForecastResultAnalysis />} />
          <Route path={"/anomaly-predict"} element={<AnamolyForecastOutputParams />} />
          <Route path={"/newmaterial-analysis"} element={<NewMaterialAnalysis />} />
          <Route path={"/results-summary"} element={<ResultsSummary />} />
          <Route path={"/change-password"} element={<ChangePassword />} />
          <Route path={"/new-chart"} element={<NewChart />} />
          <Route path={"/under-prediction-analysis"} element={<UnderPredictionAnalysis />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);


//const rootElement = document.getElementById('root')
//ReactDOM.render(<Root />, rootElement)