import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
// import redirectURL
import CountUp from "react-countup";
import SweetAlert from 'react-bootstrap-sweetalert';
import redirectURL from '../redirectURL';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BusinessDashboard = () => {
    const [necessaryData, setNecessaryData] = useState({
        phase: { label: "2024-Apr-2025-Mar", value: "2024-Apr-2025-Mar" },
        phaseList: []
    })
    useEffect(() => {
        const fetchData = async () => {
            await getNecessaryData()
        }
        fetchData()
    }, [])
    const getNecessaryData = async () => {
        const response = await redirectURL.post('dashboard/getBusinessDashboardData', {});
        if (response.data.status == "success") {
            var phaseArray = response.data.records.map((each) => ({ label: each, value: each }))
            setNecessaryData((prevState) => ({ ...prevState, phaseList: phaseArray }))
        }
    }
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: '500',
            fontSize: "13px",
            color: "black", // Set font-weight to bold for selected options
            padding: "5px"
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: "13px",
            fontWeight: '500', // Always display the selected option in bold
            color: "black"
        }),
    };
    return (
        <div className="container">
            <div className="row p-0">
                {/* <div className="col-12">
                    <p style={{ fontSize: "25px", color: "#555", fontWeight: "bold" }}>Business Dashboard</p>
                </div> */}
                <div className="d-flex col-12 p-0" style={{ backgroundColor: "", borderRadius: "5px" }}>
                    <div className="col-2">
                        <label className="new-label">Phase </label>
                        <Select
                            onChange={(event) => setNecessaryData((prevState) => ({ ...prevState, phase: event }))}
                            options={necessaryData.phaseList}
                            value={necessaryData.phase}
                            styles={customStyles}
                        />
                    </div>
                    <div className="col-2 d-flex align-items-end">
                        <button className="btn btn-success m-0">Apply</button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <div className="d-flex flex-column" style={{ width: "100%", backgroundColor: '#f1f1f1', padding: "15px", borderRadius: "5px" }}>
                        <p className="new-label mb-1" style={{fontSize: "17px", textAlign: "center"}}>AI Predictions</p>
                        <div className="d-flex justify-content-between" style={{height: "130px"}}>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "32%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                                <label className="new-label">Predicted Materials</label>
                                <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={3200} /></p>
                            </div>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "32%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                                <label className="new-label">Predicted Quantity</label>
                                <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={5124} /></p>
                            </div>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "32%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                                <label className="new-label">Predicted Cost <br />( K USD )</label>
                                <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={1435} /></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="d-flex flex-column" style={{ width: "100%", backgroundColor: '#f1f1f1', padding: "15px", borderRadius: "5px" }}>
                        <p className="new-label mb-1" style={{fontSize: "17px", textAlign: "center"}}>Business Procured Materials out of AI Predictions</p>
                        <div className="d-flex justify-content-between" style={{height: "130px"}}>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "32%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                                <label className="new-label">Procured Materials</label>
                                <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={2300} /></p>
                            </div>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "32%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                                <label className="new-label">Procured Quantity</label>
                                <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={3545} /></p>
                            </div>
                            <div className="d-flex flex-column justify-content-between" style={{ width: "32%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                                <label className="new-label">Procured Cost <br />( K USD )</label>
                                <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={1024} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-8">
                    <div className="d-flex justify-content-between" style={{ width: "100%", height: "170px", backgroundColor: '#f1f1f1', padding: "15px", borderRadius: "5px" }}>
                        <div className="d-flex flex-column justify-content-between" style={{ width: "24%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                            <label className="new-label">Materials Surpassed AI Predictions</label>
                            <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={424} /></p>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ width: "24%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                            <label className="new-label">Materials Still Under AI Predictions</label>
                            <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={589} /></p>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ width: "24%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                            <label className="new-label">Materials Procured Outside AI Predictions</label>
                            <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={710} /></p>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ width: "24%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                            <label className="new-label">Materials Surpassed AI prediction Without Justification</label>
                            <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={577} /></p>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="d-flex justify-content-between" style={{ width: "100%", height: "170px", backgroundColor: '#f1f1f1', padding: "15px", borderRadius: "5px" }}>
                        <div className="d-flex flex-column justify-content-between" style={{ width: "49%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                            <label className="new-label">( % ) Business Procured Quantity out of AI Predictions</label>
                            <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={69.18} />%</p>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ width: "49%", backgroundColor: "#f9f9f9", borderRadius: "5px", padding: "10px" }}>
                            <label className="new-label">( % ) Business Procured Cost out of AI Predictions</label>
                            <p className="m-0" style={{ fontSize: "25px", fontWeight: "bold" }}><CountUp end={71.30} />%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-8">
                    <ColumnChart />
                </div>
                <div className="col-4 d-flex flex-column">
                    <NewColumnChart />
                    <NewyColumnChart />
                </div>
            </div>
        </div>
    )
}

export default BusinessDashboard;

const ColumnChart = () => {
    const options = {
        chart: {
            type: 'column',
            backgroundColor: "#fff",
            borderRadius: "10px",
            spacingTop: 20,
            spacingRight: 20,
            spacingBottom: 20,
            spacingLeft: 20,
            height: "300px" // Consider using '100%' for responsive height
        },
        title: {
            text: ""
        },
        xAxis: {
            type: 'category',
            title: {
                text: "",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0px"
                }
            },
        },
        yAxis: {
            title: {
                text: "Quantity",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0px"
                }
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}' // Customize if needed
                }
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} ( ₹ Mn )</b><br/>'
        },
        series: [{
            name: 'Products',
            colorByPoint: true,
            data: [
                ["Materials Surpassed AI Predictions", 424], 
                ["Materials Under AI Predictions", 589], 
                ["Materials Outside AI Predictions", 710], 
                ["Materials Surpassed AI Predictions Without Justfication", 577]
            ]
        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
const NewColumnChart = () => {
    const options = {
        chart: {
            type: 'bar',
            backgroundColor: "#fff",
            borderRadius: "10px",
            spacingTop: 20,
            spacingRight: 20,
            spacingBottom: 20,
            spacingLeft: 20,
            height: "140px" // Consider using '100%' for responsive height
        },
        title: {
            text: ""
        },
        xAxis: {
            type: 'category',
            title: {
                text: "",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0px"
                }
            },
        },
        yAxis: {
            title: {
                text: "Percentage ( % )",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0px"
                }
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}' // Customize if needed
                }
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} ( ₹ Mn )</b><br/>'
        },
        series: [{
            name: 'Products',
            colorByPoint: true,
            data: [
                ["Business Procured Quantity out of AI Predictions", 69]
            ]
        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
const NewyColumnChart = () => {
    const options = {
        chart: {
            type: 'bar',
            backgroundColor: "#fff",
            borderRadius: "10px",
            spacingTop: 20,
            spacingRight: 20,
            spacingBottom: 20,
            spacingLeft: 20,
            height: "140px" // Consider using '100%' for responsive height
        },
        title: {
            text: ""
        },
        xAxis: {
            type: 'category',
            title: {
                text: "",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0px"
                }
            },
        },
        yAxis: {
            title: {
                text: "Percentage ( % )",
                style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "0px"
                }
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}' // Customize if needed
                }
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f} ( ₹ Mn )</b><br/>'
        },
        series: [{
            name: 'Products',
            colorByPoint: true,
            data: [
                ["Business Procured Cost out of AI Predictions", 71]
            ]
        }],
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
    };

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};