import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/datepicker.css';
// import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import redirectURL from '../redirectURL';
import CountUp from "react-countup";
import PieChart from "./pieChart";
import LineChart from "./lineChart";
import withRouter from '../withRouter';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

import TimeSeriesChartComponent from "../forecast/timeSeriesChart";
import TimeSeriesCountMaterialChart from "../forecast/timeseriescountmaterialchart";
import TimeSeriesQtyMaterialChart from "../forecast/timeseriesqtymaterialchart";
// import SplineChartComponent from "../forecast/splineChart";

window['jQuery'] = window['$'] = $;
var moment = require("moment");

class OverallSummaryDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showloader: "show-n",
            exact_count: 0,
            excess_count: 0,
            under_count: 0,
            excess_cost: 0,
            neverCount: 0,
            neverData: [],
            under_pred_percentage: "",
            pieSeries: [],
            lineChartSeries: [],
            lineCategories: [],
            predlineChartSeries: [],
            predlineCategories: [],
            exactData: [],
            excessData: [],
            underData: [],

            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
            },
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            showSlideBlock: "",
            overlayBlock: "show-n",
            loadshow: 'show-n',
            overly: 'show-n',
            gridState: "",
            predictedChartData: [],
            predictedResults: [],
            predictedMaterialCount: [],
            drillDownData: [],
            apiOrderData: [],
            predApiOrderData: [],

            defFY: { label: "Select", value: "" },
            selectFY: { label: "Select", value: "" },
            itemCd: { label: "Select", value: "ALL" },
            itemCodesList: [{ value: "", label: "All" }],
            total_count: 0,
            totalCountData: [],
            cost_accuracy: "",
            phase: { "value": "2023-Apr-2024-Mar", "label": "2023-Apr-2024-Mar" },
            dataAvailability: { "value": "complete", "label": "Complete Data" },
            group: { "value": "ALL", "label": "ALL" },
            phases: [],
            grouplist: [],
            predactvalue: { value: "ALL", label: "All" },
            predcatvalue: { value: "ALL", label: "All" },
            loadshow1: "show-n",
            startDate: "",
            endDate: "",
            outlier_excess_count: 0,
            outlier_excess_data: [],
            outlier_under_count: 0,
            outlier_under_data: [],
            po_count: 0,
            non_ai_count: 0,
            critical_type: { value: "ALL", label: "ALL" },
            // new_critical_type: { value: "HIGH CRITICAL", label: "CRITICAL" },
            gridStateBg: ""
        }
    }
    componentDidMount = async () => {
        var sdate = "2020-01-01";
        var edate = moment.parseZone(sdate).add(5, "months").format("YYYY-MM-DD");


        let start_date = moment.parseZone(new Date((sdate))).format("YYYY-MM-DD")
        let end_date = moment.parseZone(new Date((edate))).endOf('month').format("YYYY-MM-DD")

        this.setState({
            showloader: "show-m",
            startDate: start_date,
            endDate: end_date
        })
        await redirectURL.post("/dashboard/phaselists")
            .then((response) => {
                var records = response.data.records;
                var recordsarr = [];
                if (records.length > 0) {
                    records.map((itm) => {
                        recordsarr.push({ value: itm, label: itm });
                    })
                }
                this.setState({
                    phases: recordsarr.slice(0, recordsarr.length - 1).reverse(),
                }, () => { this.loadData() })
            })
        // this.loadData();
        this.getPredictedResults()
        // this.getDrillDownData()
        //this.getMaterialOrderDataFromApi()

        $(".datepicker").datepicker({
            dateFormat: "yy-mm-dd",
            changeYear: true,
            startDate: start_date,
            endDate: end_date
        });

    }
    // loadData() {
    //     var params = {
    //         category: this.state.critical_type.value,
    //         mtype: this.state.predcatvalue.value,
    //         phase: this.state.phase.value,
    //         group: this.state.group.value,
    //         // group: "VED",
    //         // critical_type: this.state.critical_type.value,
    //         // critical_type: "HIGH CRITICAL",
    //         // data_availability: this.state.dataAvailability.value
    //         // start_date:start_date,
    //         // end_date:end_date
    //     }
    //     redirectURL.post("dashboard/summarydata", params)
    //         .then((response) => {
    //             console.log("resonse ", response.data)
    //             if (response.data.status === "success") {
    //                 var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar",];
    //                 var predictData = response.data.predictData;
    //                 var comparisonData = response.data.comparisonData;
    //                 var avgcostaccuracy = predictData.model_accuracy_percentage
    //                 var under_pred_percentage = predictData.under_pred_percentage
    //                 console.log("comparisonData ", comparisonData)
    //                 var actualD = JSON.parse(comparisonData.future_data);
    //                 // console.log("actualD ", actualD)
    //                 var actualData = [];
    //                 var lineCategories = [];
    //                 var yearList = groupBy(actualD, rdata => rdata.year);
    //                 yearList.forEach((values, key) => {
    //                     if (values.length > 0) {
    //                         values.map((im) => {
    //                             var monthname = months[parseInt(im.month) - 1] + " " + key
    //                             lineCategories.push(monthname);
    //                             actualData.push({
    //                                 name: monthname,
    //                                 y: im.total_cost,
    //                                 ytxt: "$" + im.cost_in_k,
    //                                 // diff_percent:im.diff_percentage,
    //                                 month_no: im.month
    //                             });
    //                         })
    //                     }
    //                 })
    //                 actualData = actualData.sort(GetSortAscOrder("month_no"));
    //                 var predctD = JSON.parse(comparisonData.prediction_data);
    //                 // console.log("predctD ", predctD)
    //                 var predData = [];
    //                 var predlineCategories = [];
    //                 var predyearList = groupBy(predctD, rdata => rdata.year);
    //                 predyearList.forEach((values, key) => {
    //                     if (values.length > 0) {
    //                         values.map((im) => {
    //                             var monthname = months[parseInt(im.month) - 1] + " " + key
    //                             predlineCategories.push(monthname);
    //                             predData.push({
    //                                 name: monthname,
    //                                 y: im.total_cost,
    //                                 ytxt: "$" + im.cost_in_k,
    //                                 diff_percent: im.diff_percentage,
    //                                 month_no: im.month
    //                             });
    //                         })
    //                     }
    //                 })
    //                 predData = predData.sort(GetSortAscOrder("month_no"));

    //                 var pieSeries = [{
    //                     name: 'Count',
    //                     colorByPoint: false,
    //                     data: [
    //                         {
    //                             name: 'AI - Sensitive Materials',
    //                             y: predictData.po_count,
    //                             color: "#809fff",
    //                             sliced: true,
    //                             selected: true,
    //                             dataLabels: {
    //                                 enabled: true
    //                             }
    //                         },
    //                         {
    //                             name: 'Never Consumed Materials',
    //                             y: predictData.never_count,
    //                             color: "#4793AF",
    //                             sliced: true,
    //                             selected: true,
    //                             dataLabels: {
    //                                 enabled: true
    //                             }
    //                         },
    //                         {
    //                             name: 'Non - AI Sensitive Materials',
    //                             y: predictData.non_ai_count,
    //                             color: "#00cccc",
    //                             sliced: true,
    //                             selected: true,
    //                             dataLabels: {
    //                                 enabled: true
    //                             }
    //                         },
    //                         {
    //                             name: 'Exact Prediction Materials',
    //                             y: predictData.exact_count,
    //                             color: "#03C04A",
    //                             sliced: true,
    //                             selected: true,
    //                             dataLabels: {
    //                                 enabled: true
    //                             }
    //                         },
    //                         {
    //                             name: 'Excess Prediction Materials',
    //                             y: predictData.excess_count,
    //                             color: "#FF3535",
    //                             sliced: true,
    //                             selected: true,
    //                             dataLabels: {
    //                                 enabled: true
    //                             }
    //                         },
    //                         {
    //                             name: 'Under Prediction Materials',
    //                             y: predictData.under_count,
    //                             color: "orange",
    //                             sliced: true,
    //                             selected: true,
    //                             dataLabels: {
    //                                 enabled: true
    //                             }
    //                         }
    //                     ], colors: [
    //                         '#00e887',
    //                         '#0ff3a0',
    //                         '#4caefe',
    //                         '#FF3535',
    //                         '#23e274',
    //                         '#2dd9db',
    //                         '#1feeaf'
    //                     ]
    //                 }];
    //                 this.getComparisionActualPredictCharts(comparisonData)
    //                 console.log(pieSeries, "u g")
    //                 // var lineChartSeries = [{
    //                 //     name:"Actual Cost",
    //                 //     data:actualData,
    //                 //     dataLabels: {
    //                 //         enabled: true
    //                 //     }
    //                 // },{
    //                 //     name:"Predicted Cost",
    //                 //     data:predData,
    //                 //     dataLabels: {
    //                 //         enabled: true
    //                 //     }
    //                 // }]
    //                 // var total_count = parseInt(predictData.exact_count)+parseInt(predictData.excess_count)+parseInt(predictData.under_count)+parseInt(predictData.outlier_excess_count)+parseInt(predictData.outlier_under_count);
    //                 // var totalCountData = predictData.total_materials_count;
    //                 console.log(pieSeries, "u g")
    //                 //console.log("abbreviateNumber(number);",abbreviateNumber(predictData.excess_cost));

    //                 // var cost_accuracy = Math.round((300000/parseInt(predictData.excess_cost))*100);
    //                 //console.log("predictData.outlier_excess_data ", predictData.outlier_excess_data)
    //                 if(localStorage.getItem("role") == "enmovil_admin"){
    //                     if(this.state.phase.value === "2020-Apr-2021-Mar"){
    //                         under_pred_percentage = 15
    //                         avgcostaccuracy = 85
    //                     } else if (this.state.phase.value === "2021-Apr-2022-Mar"){
    //                         under_pred_percentage = 13
    //                         avgcostaccuracy = 87
    //                     }else if (this.state.phase.value === "2022-Apr-2023-Mar"){
    //                         under_pred_percentage = 9
    //                         avgcostaccuracy = 91
    //                     }else if (this.state.phase.value === "2023-Apr-2024-Mar"){
    //                         under_pred_percentage = 7
    //                         avgcostaccuracy = 93
    //                     }
    //                 }
    //                 this.setState({
    //                     exact_count: predictData.exact_count,
    //                     excess_count: predictData.excess_count,
    //                     under_count: predictData.under_count,
    //                     total_count: predictData.total_materials_count,
    //                     exactData: JSON.parse(predictData.Exact_data),
    //                     excessData: JSON.parse(predictData.Excess_data),
    //                     underData: JSON.parse(predictData.Under_data),
    //                     po_count: predictData.po_count,
    //                     non_ai_count: predictData.non_ai_count,
    //                     no_ai_data: JSON.parse(predictData.no_ai_data),
    //                     po_data: JSON.parse(predictData.po_data),
    //                     totalCountData: JSON.parse(predictData.prediction_data),
    //                     excess_cost: predictData.excess_cost,
    //                     cost_accuracy: avgcostaccuracy,
    //                     under_pred_percentage: under_pred_percentage,
    //                     showloader: "show-n",
    //                     pieSeries: pieSeries,
    //                     neverCount: predictData.never_count,
    //                     neverData: JSON.parse(predictData.never_data)
    //                     // outlier_excess_count:predictData.outlier_excess_count,
    //                     // outlier_excess_data:JSON.parse(predictData.outlier_excess_data),
    //                     // outlier_under_count:predictData.outlier_under_count,
    //                     // outlier_under_data:JSON.parse(predictData.outlier_under_data)
    //                     // lineChartSeries:lineChartSeries,
    //                     // lineCategories:lineCategories
    //                 })

    //             } else {
    //                 console.log("error")
    //             }
    //         })
    // }
    async loadData() {
        var params = {
            category: this.state.critical_type.value,
            mtype: this.state.predcatvalue.value,
            phase: this.state.phase.value,
            group: this.state.group.value,
            fy_half: "ALL"
            // group: "VED",
            // critical_type: this.state.critical_type.value,
            // critical_type: "HIGH CRITICAL",
            // data_availability: this.state.dataAvailability.value
            // start_date:start_date,
            // end_date:end_date
        }
        await redirectURL.post("dashboard/newsummarydata", params)
            .then((response) => {
                if (response.data.status == "success") {
                    var months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar",];
                    var actualD = JSON.parse(response.data.result[0].future_data);
                    // console.log("actualD ", actualD)
                    var actualData = [];
                    var lineCategories = [];
                    var yearList = groupBy(actualD, rdata => rdata.year);
                    yearList.forEach((values, key) => {
                        if (values.length > 0) {
                            values.map((im) => {
                                var monthname = months[parseInt(im.month) - 1] + " " + key
                                lineCategories.push(monthname);
                                actualData.push({
                                    name: monthname,
                                    y: im.total_cost,
                                    ytxt: "$" + im.cost_in_k,
                                    // diff_percent:im.diff_percentage,
                                    month_no: im.month
                                });
                            })
                        }
                    })
                    actualData = actualData.sort(GetSortAscOrder("month_no"));
                    var predctD = JSON.parse(response.data.result[0].prediction_data);
                    // console.log("predctD ", predctD)
                    var predData = [];
                    var predlineCategories = [];
                    var predyearList = groupBy(predctD, rdata => rdata.year);
                    predyearList.forEach((values, key) => {
                        if (values.length > 0) {
                            values.map((im) => {
                                var monthname = months[parseInt(im.month) - 1] + " " + key
                                predlineCategories.push(monthname);
                                predData.push({
                                    name: monthname,
                                    y: im.total_cost,
                                    ytxt: "$" + im.cost_in_k,
                                    diff_percent: im.diff_percentage,
                                    month_no: im.month
                                });
                            })
                        }
                    })
                    predData = predData.sort(GetSortAscOrder("month_no"));
                    var pieSeries = [{
                        name: 'Count',
                        colorByPoint: false,
                        data: [
                            {
                                name: 'AI - Sensitive Materials',
                                y: response.data.result[0].po_count,
                                color: "#809fff",
                                sliced: true,
                                selected: true,
                                dataLabels: {
                                    enabled: true
                                }
                            },
                            {
                                name: 'Non - AI Sensitive Materials',
                                y: response.data.result[0].non_ai_count,
                                color: "#00cccc",
                                sliced: true,
                                selected: true,
                                dataLabels: {
                                    enabled: true
                                }
                            },
                            {
                                name: 'Never Consumed Materials',
                                y: response.data.result[0].never_count,
                                color: "#4793AF",
                                sliced: true,
                                selected: true,
                                dataLabels: {
                                    enabled: true
                                }
                            },
                            {
                                name: 'Exact Prediction Materials',
                                y: response.data.result[0].exact_count,
                                // color: "#03C04A",
                                color: "#90D26D",
                                sliced: true,
                                selected: true,
                                dataLabels: {
                                    enabled: true
                                }
                            },
                            {
                                name: 'Excess Prediction Materials',
                                y: response.data.result[0].excess_count,
                                // color: "#FF3535",
                                color: "#FA7070",
                                sliced: true,
                                selected: true,
                                dataLabels: {
                                    enabled: true
                                }
                            },
                            {
                                name: 'Under Prediction Materials',
                                y: response.data.result[0].under_count,
                                // color: "orange",
                                color: "#FFC374",
                                sliced: true,
                                selected: true,
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        ], colors: [
                            '#00e887',
                            '#0ff3a0',
                            '#4caefe',
                            '#FF3535',
                            '#23e274',
                            '#2dd9db',
                            '#1feeaf'
                        ]
                    }];
                }
                this.getComparisionActualPredictCharts(response.data.result[0])
                this.setState({
                    exact_count: response.data.result[0].exact_count,
                    excess_count: response.data.result[0].excess_count,
                    under_count: response.data.result[0].under_count,
                    total_count: response.data.result[0].total_materials_count,
                    // exactData: JSON.parse(response.data.result[0].Exact_data),
                    // excessData: JSON.parse(response.data.result[0].Excess_data),
                    // underData: JSON.parse(response.data.result[0].Under_data),
                    po_count: response.data.result[0].po_count,
                    non_ai_count: response.data.result[0].non_ai_count,
                    // no_ai_data: JSON.parse(response.data.result[0].no_ai_data),
                    // po_data: JSON.parse(response.data.result[0].po_data),
                    // totalCountData: JSON.parse(response.data.result[0].prediction_data),
                    excess_cost: response.data.result[0].excess_cost,
                    cost_accuracy: response.data.result[0].model_accuracy_percentage,
                    under_pred_percentage: response.data.result[0].under_pred_percentage,
                    showloader: "show-n",
                    pieSeries: pieSeries,
                    neverCount: response.data.result[0].never_count,
                    // neverData: JSON.parse(response.data.result[0].never_data)
                    // outlier_excess_count:predictData.outlier_excess_count,
                    // outlier_excess_data:JSON.parse(predictData.outlier_excess_data),
                    // outlier_under_count:predictData.outlier_under_count,
                    // outlier_under_data:JSON.parse(predictData.outlier_under_data)
                    // lineChartSeries:lineChartSeries,
                    // lineCategories:lineCategories
                })
            })
        await redirectURL.post("dashboard/newsummaryCountersdata", params)
            .then((response) => {
               console.log(response, "response")
               if(response.data.status == "success"){
                this.setState({
                    exactData: JSON.parse(response.data.predictData.Exact_data),
                    excessData: JSON.parse(response.data.predictData.Excess_data),
                    underData: JSON.parse(response.data.predictData.Under_data),
                    no_ai_data: JSON.parse(response.data.predictData.no_ai_data),
                    po_data: JSON.parse(response.data.predictData.po_data),
                    totalCountData: JSON.parse(response.data.predictData.prediction_data),
                    neverData: JSON.parse(response.data.predictData.never_data)
                })
               }
            })
    }
    getComparisionActualPredictCharts(comparisonData) {
        // console.log("comparisonData ", comparisonData)
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        // var comparisonData = response.data.comparisonData;
        var actualD = JSON.parse(comparisonData.future_data);
        var actualData = [];
        var lineCategories = [];
        var yearList = groupBy(actualD, rdata => rdata.year);
        yearList.forEach((values, key) => {
            if (values.length > 0) {
                values.map((im) => {
                    var monthname = months[parseInt(im.month) - 1] + " " + key
                    lineCategories.push(monthname);
                    actualData.push({
                        name: monthname,
                        y: im.total_cost,
                        ytxt: "$" + im.cost_in_k,
                        // diff_percent:im.diff_percentage,
                        month_no: im.month
                    });
                })
            }
        })
        actualData = actualData.sort(GetSortAscOrder("month_no"));
        actualData = [...actualData.slice(3, 12), ...actualData.slice(0, 3),]
        var predctD = JSON.parse(comparisonData.prediction_data);
        console.log(predctD, "")
        var predData = [];
        var predlineCategories = [];
        var predyearList = groupBy(predctD, rdata => rdata.year);
        predyearList.forEach((values, key) => {
            if (values.length > 0) {
                values.map((im) => {
                    var monthname = months[parseInt(im.month) - 1] + " " + key
                    predlineCategories.push(monthname);
                    predData.push({
                        name: monthname,
                        y: im.total_cost,
                        ytxt: im.cost_in_k,
                        diff_percent: im.diff_percentage,
                        month_no: im.month
                    });
                })
            }
        })
        predData = predData.sort(GetSortAscOrder("month_no"));
        predData = [...predData.slice(3, 12), ...predData.slice(0, 3)]
        console.log(actualData, predData)

        var lineChartSeries = [{
            name: "Actual Quantity",
            data: actualData,
            dataLabels: {
                enabled: true
            }
        }, {
            name: "Prediction Quantity",
            data: predData,
            dataLabels: {
                enabled: true
            }
        }]
        // console.log("lineChartSeries ", lineChartSeries)
        this.setState({
            lineChartSeries: lineChartSeries,
            lineCategories: lineCategories,
            loadshow1: "show-n"
        })
    }
    getPhaseList() {
        var items = []
        items.push({
            value: "2022-Jul-Dec", label: "2022-Jul-Dec"
        })
        return items;
    }
    getGroupList() {
        var items = []
        items.push(
            {
                value: "VED", label: "VED"
                //     }, {
                //     value: "NON VED", label: "NON VED"
                // }, {
                //     value: "ALL", label: "ALL"
                // }
            })
        return items;
    }
    getDrillDownData = () => {
        let drillDownData = [];
        let params = {
            year: 2020,
            month_start: 1,
            month_end: 6
        }
        redirectURL.post("/forecast/getDrillDownData")
            .then(response => {
                let data = response.data
                let predQty = [{ name: "Jan", y: data[0].month_one_pred_quantity, color: 'blue', lbl: data[0].month_one_pred_quantity.toLocaleString() },
                { name: "Feb", y: data[0].month_two_pred_quantity, color: 'blue', lbl: data[0].month_two_pred_quantity.toLocaleString() },
                { name: "Mar", y: data[0].month_three_pred_quantity, color: 'blue', lbl: data[0].month_three_pred_quantity.toLocaleString() },
                { name: "April", y: data[0].month_four_pred_quantity, color: 'blue', lbl: data[0].month_four_pred_quantity.toLocaleString() },
                { name: "May", y: data[0].month_five_pred_quantity, color: 'blue', lbl: data[0].month_five_pred_quantity.toLocaleString() },
                { name: "June", y: data[0].month_six_pred_quantity, color: 'blue', lbl: data[0].month_six_pred_quantity.toLocaleString() },
                ]
                let actualQty = [{ name: "Jan", y: data[0].month_one_actual_quantity, color: 'blue', lbl: data[0].month_one_actual_quantity.toLocaleString() },
                { name: "Feb", y: data[0].month_two_actual_quantity, color: 'blue', lbl: data[0].month_two_actual_quantity.toLocaleString() },
                { name: "Mar", y: data[0].month_three_actual_quantity, color: 'blue', lbl: data[0].month_three_actual_quantity.toLocaleString() },
                { name: "April", y: data[0].month_four_actual_quantity, color: 'blue', lbl: data[0].month_four_actual_quantity.toLocaleString() },
                { name: "May", y: data[0].month_five_actual_quantity, color: 'blue', lbl: data[0].month_five_actual_quantity.toLocaleString() },
                { name: "June", y: data[0].month_six_actual_quantity, color: 'blue', lbl: data[0].month_six_actual_quantity.toLocaleString() },
                ]
                drillDownData.push(actualQty)
                drillDownData.push(predQty)

                this.setState({ drillDownData })
            })
    }
    getMaterialOrderDataFromApi = () => {

        let param = {}
        this.setState({ showLoader: "show-m", apiOrderData: [], predApiOrderData: [] })
        if (this.state.itemCd.value != "") {
            param.item_code = this.state.itemCd.value,
                param.phase = this.state.phase.value
            // param.critical_type = this.state.critical_type.value,
            // param.group = this.state.group.value
        }
        let apiOrderData = [];
        let predApiOrderData = [];

        redirectURL.post("/forecast/getMaterialOrderDataFromApi", param)
            .then(response => {
                console.log(JSON.parse(response.data.final_actual_data), "ersf")
                try {

                    let data = JSON.parse(response.data.final_actual_data)

                    let predData = JSON.parse(response.data.predicted_data)
                    data.map(item => item.dataTimeStamp = (new Date(item.date)).getTime() + (60 * 60 * 24 * 1000))
                    // console.log(data,'daaa')
                    predData.map(item => item.dataTimeStamp = (new Date(item.date)).getTime() + (60 * 60 * 24 * 1000))

                    // console.log(predData)

                    let itemQnty = [];
                    data.map(item => {
                        itemQnty.push([item.dataTimeStamp, item.total_item_quantity])
                    })
                    let materialCount = [];
                    data.map(item => {
                        materialCount.push([item.dataTimeStamp, item.unique_material_count])
                    })

                    apiOrderData.push(itemQnty)
                    apiOrderData.push(materialCount)

                    let predItemQnty = [];
                    predData.map(item => {
                        predItemQnty.push([item.dataTimeStamp, item.total_item_quantity])
                    })
                    let predMaterialCount = [];
                    predData.map(item => {
                        predMaterialCount.push([item.dataTimeStamp, item.unique_material_count])
                    })

                    predApiOrderData.push(predItemQnty)
                    predApiOrderData.push(predMaterialCount)
                    this.setState({ apiOrderData, predApiOrderData, showLoader: "show-n" })
                    console.log(apiOrderData, "apiOrder")
                    console.log(predApiOrderData, "predApi")

                } catch (error) {

                }
            })
    }
    getPredictedResults = () => {
        redirectURL.post("/forecast/getForecastingPredictionResults")
            .then(async response => {
                if (response.data.status == 'success') {
                    let records = response.data.predictionResults
                    let materialCount = records.length
                    // let predictedMaterialCount = [{name: '2020', y: materialCount, lbl: materialCount.toLocaleString()}]
                    // console.log(records,'predrec')
                    let sum = 0;
                    let nonZeroItemQty = 0;
                    for (const obj of records) {
                        if (obj.item_quantity != 0) {
                            nonZeroItemQty += 1
                        }
                        sum += obj.item_quantity;
                    }
                    let predictedChartData = [{ name: '2020', y: sum, lbl: sum.toLocaleString() }]
                    let predictedMaterialCount = [{ name: '2020', y: nonZeroItemQty, lbl: nonZeroItemQty.toLocaleString() }]

                    this.setState({
                        predictedResults: records,
                        predictedChartData: predictedChartData,
                        predictedMaterialCount: predictedMaterialCount
                    })
                }
            })
    }

    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlock: "",
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }

    onClickCounterShowData = async (oVal, color) => {

        await this.setState({
            showSlideBlock: "",
            overlayBlock: 'show-n',
            rowData: [],
            gridState: ""
        })
        await setTimeout(() => {
            if (oVal === 0) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.totalCountData,
                    gridState: "Complete Prediction Data",
                    gridStateBg: color
                })
            }
            if (oVal === 1) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.po_data,
                    gridState: "AI sensitive Materials Data",
                    gridStateBg: color
                })
            }
            if (oVal === 2) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.no_ai_data,
                    gridState: "Non AI Sensitive Materials Data",
                    gridStateBg: color
                })
            }
            if (oVal === 3) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.neverData,
                    gridState: "Never Consumed Materials Data",
                    gridStateBg: color
                })
            }
            if (oVal === 4) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.exactData,
                    gridState: "Exact Prediction Materials Data",
                    gridStateBg: color
                })
            }
            if (oVal === 5) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.excessData,
                    gridState: "Excess Prediction Materials Data",
                    gridStateBg: color
                })
            }
            if (oVal === 6) {
                this.setState({
                    showSlideBlock: "slide80",
                    overlayBlock: 'show-m',
                    rowData: this.state.underData,
                    gridState: "Under Prediction Materials Data",
                    gridStateBg: color
                })
            }
        }, 500)
    }
    onChangeGroup(group) {
        this.setState({ group })
    }
    onChangeDataAvailability(dataAvailability) {
        this.setState({ dataAvailability })
    }
    onChangePhase(phase) {
        this.setState({ phase })
    }
    onChangeCriticalType(critical_type) {
        this.setState({ critical_type })
    }
    onChangePredActValue(predactvalue) {
        this.setState({
            loadshow1: "show-m"
        })
        this.setState({ predactvalue }, () => {
            var params = {
                category: this.state.predactvalue.value,
                mtype: this.state.predcatvalue.value,
                start_date: $("#startDate").val(),
                end_date: $("#endDate").val()
            }
            redirectURL.post("dashboard/comparisionCostData", params)
                .then((response) => {
                    // console.log("resonse ", response.data)
                    try {
                        var comparisonData = response.data.comparisonData;
                        this.getComparisionActualPredictCharts(comparisonData)
                        // var months = ["Jan", "Feb", "Mar", "Apr","May","Jun","Jul", "Aug","Sep","Oct","Nov", "Dec"];
                        //     var predictData = response.data.predictData;
                        //     var comparisonData = response.data.comparisonData;
                        //     // console.log("comparisonData ", comparisonData)
                        //     var actualD = JSON.parse(comparisonData.future_data);
                        //     // console.log("actualD ", actualD)
                        //     var actualData=[];
                        //     var lineCategories = [];
                        //     var yearList = groupBy(actualD, rdata => rdata.year);
                        //     yearList.forEach((values,key) => {
                        //         if(values.length > 0)
                        //         {
                        //             values.map((im) => {
                        //                 var monthname = months[parseInt(im.month)-1]+" "+key
                        //                 lineCategories.push(monthname);
                        //                 actualData.push({
                        //                     name:monthname,
                        //                     y:im.total_cost,
                        //                     ytxt:"$"+im.cost_in_k,
                        //                     // diff_percent:im.diff_percentage,
                        //                     month_no:im.month
                        //                 });
                        //             })
                        //         }
                        //     })
                        //     actualData = actualData.sort(GetSortAscOrder("month_no"));
                        //     var predctD = JSON.parse(comparisonData.prediction_data);
                        //    // console.log("predctD ", predctD)
                        //     var predData=[];
                        //     var predlineCategories = [];
                        //     var predyearList = groupBy(predctD, rdata => rdata.year);
                        //     predyearList.forEach((values,key) => {
                        //         if(values.length > 0)
                        //         {
                        //             values.map((im) => {
                        //                 var monthname = months[parseInt(im.month)-1]+" "+key
                        //                 predlineCategories.push(monthname);
                        //                 predData.push({
                        //                     name:monthname,
                        //                     y:im.total_cost,
                        //                     ytxt:"$"+im.cost_in_k,
                        //                     diff_percent:im.diff_percentage,
                        //                     month_no:im.month
                        //                 });
                        //             })
                        //         }
                        //     })
                        //     predData = predData.sort(GetSortAscOrder("month_no"));


                        //     var lineChartSeries = [{
                        //         name:"Actual Cost",
                        //         data:actualData,
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     },{
                        //         name:"Prediction Cost",
                        //         data:predData,
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     }]
                        //     this.setState({
                        //         lineChartSeries:lineChartSeries,
                        //         lineCategories:lineCategories,
                        //         loadshow1:"show-n"
                        //     })
                    } catch (error) {

                    }
                })
        })
    }

    onChangePredCategoryValue(predcatvalue) {
        this.setState({
            loadshow1: "show-m"
        })
        this.setState({ predcatvalue }, () => {
            var params = {
                group: this.state.group.value,
                phase: this.state.phase.value,
                category: this.state.critical_type.value,
                mtype: this.state.predcatvalue.value,
                // start_date:$("#startDate").val(),
                // end_date:$("#endDate").val()
            }
            redirectURL.post("dashboard/comparisionCostData", params)
                .then((response) => {
                    // console.log("resonse ", response.data)
                    try {
                        var comparisonData = response.data.comparisonData;
                        this.getComparisionActualPredictCharts(comparisonData)

                        //     var months = ["Jan", "Feb", "Mar", "Apr","May","Jun","Jul", "Aug","Sep","Oct","Nov", "Dec"];
                        //     var predictData = response.data.predictData;
                        //     var comparisonData = response.data.comparisonData;
                        //     // console.log("comparisonData ", comparisonData)
                        //     var actualD = JSON.parse(comparisonData.future_data);
                        //     // console.log("actualD ", actualD)
                        //     var actualData=[];
                        //     var lineCategories = [];
                        //     var yearList = groupBy(actualD, rdata => rdata.year);
                        //     yearList.forEach((values,key) => {
                        //         if(values.length > 0)
                        //         {
                        //             values.map((im) => {
                        //                 var monthname = months[parseInt(im.month)-1]+" "+key
                        //                 lineCategories.push(monthname);
                        //                 actualData.push({
                        //                     name:monthname,
                        //                     y:im.total_cost,
                        //                     ytxt:"$"+im.cost_in_k,
                        //                     // diff_percent:im.diff_percentage,
                        //                     month_no:im.month
                        //                 });
                        //             })
                        //         }
                        //     })
                        //     actualData = actualData.sort(GetSortAscOrder("month_no"));
                        //     var predctD = JSON.parse(comparisonData.prediction_data);
                        //    // console.log("predctD ", predctD)
                        //     var predData=[];
                        //     var predlineCategories = [];
                        //     var predyearList = groupBy(predctD, rdata => rdata.year);
                        //     predyearList.forEach((values,key) => {
                        //         if(values.length > 0)
                        //         {
                        //             values.map((im) => {
                        //                 var monthname = months[parseInt(im.month)-1]+" "+key
                        //                 predlineCategories.push(monthname);
                        //                 predData.push({
                        //                     name:monthname,
                        //                     y:im.total_cost,
                        //                     ytxt:"$"+im.cost_in_k,
                        //                     diff_percent:im.diff_percentage,
                        //                     month_no:im.month
                        //                 });
                        //             })
                        //         }
                        //     })
                        //     predData = predData.sort(GetSortAscOrder("month_no"));


                        //     var lineChartSeries = [{
                        //         name:"Actual Cost",
                        //         data:actualData,
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     },{
                        //         name:"Prediction Cost",
                        //         data:predData,
                        //         dataLabels: {
                        //             enabled: true
                        //         }
                        //     }]
                        //     this.setState({
                        //         lineChartSeries:lineChartSeries,
                        //         lineCategories:lineCategories,
                        //         loadshow1:"show-n"
                        //     })
                    } catch (error) {

                    }
                })
        })
    }

    setStartDate = (date) => {
        this.setState({ startDate: date })
    }
    setEndDate = (date) => {
        this.setState({ endDate: date })
    }
    onClickGetData() {
        this.setState({
            showloader: "show-m",
            startDate: $("#startDate").val(),
            endDate: $("#endDate").val()
        })
        var params = {
            // category:this.state.predcatvalue.value,
            mtype: this.state.predcatvalue.value,
            // group: this.state.group.value,
            group: "VED",
            phase: this.state.phase.value,
            // critical_type: this.state.critical_type.value,
            critical_type: "HIGH CRITICAL",
            data_availability: this.state.dataAvailability.value

            // start_date:$("#startDate").val(),
            // end_date:$("#endDate").val()
        }
        this.loadData(params);
        // this.getMaterialOrderDataFromApi()
    }
    render() {
        var colDefs = [
            {
                headerName: "Item Code",
                field: 'item_code',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Item Description",
                field: 'item_description',
                width: "220",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material type",
                field: 'material_type',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Classification",
                field: 'material_classification',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "FC Period (months)",
                field: 'fc_period',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Unit Cost ( $ )",
                field: 'unit_cost',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Actual Quantity",
                field: 'actual_quantity',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted Quantity",
                field: 'predicted_quantity',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Actual Cost ( $ )",
                field: 'actual_cost',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Predicted Cost ( $ )",
                field: 'predicted_cost',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Cost Difference",
                field: 'cost_difference',
                width: "120",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Category",
                field: 'category',
                width: "120",
                headerClass: ["column-header-style"]
            }

        ]
        if (localStorage.getItem("tn") === "" || localStorage.getItem("tn") === undefined) {
            return <Navigate to={"/login"} push={true} />
        }
        var periodCheck = parseInt(this.state.phase.value.slice(0, 4))
        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                fontWeight: 'bold',
                fontSize: "14px",
                color: "black" // Set font-weight to bold for selected options
            }),
            singleValue: (provided, state) => ({
                ...provided,
                fontSize: "14px",
                fontWeight: 'bold', // Always display the selected option in bold
                color: "black"
            }),
        };
        const applyMediaQueryStyles = () => {
            if (window.innerWidth >= 1920) {
                customStyles.option = (provided, state) => ({
                    ...provided,
                    fontSize: "17px",
                    fontWeight: 'bold', // Change font size for 1920px screens
                    color: "black",
                });

                customStyles.singleValue = (provided, state) => ({
                    ...provided,
                    fontSize: "17px",
                    fontWeight: 'bold', // Change font size for 1920px screens
                    color: "black",
                });
                customStyles.control = (provided) => ({
                    ...provided,
                    height: '40px', // Set the desired height
                });
            }
        };

        // Call the media query function to apply styles initially and when the window is resized
        applyMediaQueryStyles();

        // Listen for window resize events and reapply styles as needed
        window.addEventListener("resize", applyMediaQueryStyles);
        //   console.log(this.state.total_count, "hey u g")
        console.log(this.state.totalCountData)
        return (
            <div>
                <div className={'lodr ' + (this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                <div className="row mb-0p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <h5 className='fbold f23 d-flex justify-content-between' style={{ color: "black" }}>
                            <span>Forecast Analysis Dashboard</span>
                        </h5>
                    </div>
                </div>
                <div className="row mb-10p pt-20p">
                    <div className="col-sm-2 custom-col">
                        <label className="mt-10p fbold f17" style={{ color: "black" }}>Period </label>
                        <Select
                            onChange={this.onChangePhase.bind(this)}
                            options={this.state.phases}
                            value={this.state.phase}
                            styles={customStyles}
                        />
                    </div>
                    <div className="col-sm-2 custom-col">
                        <label className="mt-10p fbold f17" style={{ color: "black" }}>Material Type </label>
                        <Select
                            onChange={this.onChangeCriticalType.bind(this)}
                            // options={periodCheck < 2022 ? [{ value: "HIGH CRITICAL", label: "CRITICAL" }, { value: "LOW CRITICAL", label: "NON CRITICAL" }, { value: "ALL", label: "All" }] : [{ value: "HIGH CRITICAL", label: "CRITICAL" }, { value: "ALL", label: "All" }]}
                            options={localStorage.getItem("role") == "enmovil_admin" ? [{ "value": "HIGH CRITICAL", "label": "CRITICAL" }, { "value": "NON CRITICAL", "label": "NON CRITICAL" }, { "value": "ALL", "label": "ALL" }] : [{ "value": "HIGH CRITICAL", "label": "CRITICAL" }, { "value": "ALL", "label": "ALL" }]}
                            value={this.state.critical_type}
                            styles={customStyles}
                        />
                    </div>
                    <div className="col-sm-2 custom-col">
                        <label className="mt-10p fbold f17" style={{ color: "black" }}>Material Group </label>
                        <Select
                            onChange={this.onChangeGroup.bind(this)}
                            // options={this.getGroupList()}
                            options={localStorage.getItem("role") == "enmovil_admin" ? [{ "value": "VED", "label": "VED" }, { "value": "NON VED", "label": "NON VED" }, { "value": "ALL", "label": "ALL" }] : [{ "value": "VED", "label": "VED" }, { "value": "ALL", "label": "ALL" }]}
                            value={this.state.group}
                            styles={customStyles}
                        />
                    </div>
                    {/* <div className="col-sm-2 custom-col">
                        <label className="mt-10p fbold f17" style={{ color: "black" }}>Data Availability</label>
                        <Select
                            onChange={this.onChangeDataAvailability.bind(this)}
                            options={[{ "value": "complete", "label": "Complete Data" }, { "value": "incomplete", "label": "Incomplete Data" }]}
                            value={this.state.dataAvailability}
                            styles={customStyles}
                        />
                    </div> */}

                    {/* <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">Start Date</label>
                            
                            <input type='text' className="datepicker form-inputbox forminp" id="startDate" name="startDate" value={this.state.startDate} />
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group ">
                            <label className="col-form-label f12">To Date</label>
                          
                            <input type='text' className="datepicker form-inputbox forminp" id="endDate" name="endDate" value={this.state.endDate} />
                        </div>
                    </div> */}
                    <div className="col-sm-2 mt-5p">
                        <button type="button" className="btn btn-warning getBtn38 fw-600" onClick={this.onClickGetData.bind(this)}>Apply</button>
                    </div>
                </div>
                {/* <div className="row mb-20p pt-20p beffect">
                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0" style={{height:"150px",background:"#fff"}}>
                            <div className="card" style={{border:"0px",height:"170px"}}>
                                <div className="card-body" id="counterCardBody" onClick={this.onClickCounterShowData.bind(this,0)}>
                                    <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "black"}}>
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <p className="m-0p">Training Dataset</p>
                                        <p className="m-5p">Material Count</p>
                                        </div>
                                    <span className="xtooltip"><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext p-5p">Total number of materials for which prediction is performed</span>
                                        </span>
                                    </div>
                                    <h4 className="txt-info-dark f20 mt-20p"><span className="counter">
									<CountUp end={this.state.total_count} delay={0} />
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
					<div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0" style={{height:"150px",background:"#fff"}}>
                            <div className="card" style={{border:"0px",height:"170px"}}>
                                <div className="card-body" id="counterCardBody" onClick={this.onClickCounterShowData.bind(this,1)}>
                                    <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold"}} className="txt-success-dark">
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <p className="m-0p">Accurate Material</p>
                                        <p className="m-5p">Forecast Count</p>
                                        </div>
                                    <span className="xtooltip" style={{color: "black"}}><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext p-5p">Materials for which actual consumption matchs predicted consumption</span>
                                        </span>
                                    </div>
                                    <h4 className="txt-success-dark f20 mt-20p"><span className="counter"><CountUp end={this.state.exact_count} delay={0} /></span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0" style={{height:"150px",background:"#fff"}}>
                            <div className="card" style={{border:"0px",height:"170px"}}>
                                <div className="card-body" id="counterCardBody" onClick={this.onClickCounterShowData.bind(this,2)}>                            
                                        <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "black"}}>
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <p className="m-0p txt-danger-dark">Overestimated Material</p>
                                        <div className="mt-5p">
                                        <span className="m-5p txt-success-dark">Forecasting Count</span><span>/</span>
                                        <span className="txt-secondary-dark">  Cost</span>
                                        </div>
                                        </div>
                                    <span className="xtooltip"><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext p-5p">Materials for which predicted consumption is greater than the actual consumption</span>
                                        </span>
                                    </div>
                                    <h4 className="txt-success-dark f20 mt-10p">
                                        <span className="counter">
                                            &nbsp;&nbsp;&nbsp;<CountUp end={this.state.excess_count} delay={0} />  <br />
                                            <span className="txt-secondary-dark">
                                            $ {this.state.excess_cost}M
                                            </span>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0" style={{height:"150px",background:"#fff"}}>
                            <div className="card" style={{border:"0px",height:"170px"}}>
                                <div className="card-body" id="counterCardBody" onClick={this.onClickCounterShowData.bind(this,3)}>                            
                                    
                                        <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "black"}}>
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <p className="m-0p txt-orange-dark">Underestimated Materials</p>
                                        <div className="mt-5p">
                                        <span className="m-5p txt-danger-dark">Forecasting Count </span><span>/</span>
                                        <span className="txt-info-dark"> Percentage</span>
                                        </div>
                                        </div>
                                        <span className="xtooltip"><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext p-5p">Materials for which predicted consumption is less than the actual consumption</span>
                                        </span>
                                </div>
                                    <h4 className="txt-danger-dark f20 mt-10p">
                                        <span className="counter">
                                            <CountUp end={this.state.under_count} delay={0} />  <br />
                                            <span className="txt-info-dark"><CountUp end={this.state.under_pred_percentage} delay={0} /> %</span>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">                            
                                    <span className="f13 countertxt">Excess Inventory Cost Incurred 
                                        <span className="xtooltip"><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext">Predicted cost in excess of actual consumption</span>
                                        </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <h4 className="txt-secondary-dark f30"><span className="counter">$ <CountUp end={this.state.excess_cost} delay={0} />
									
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0" style={{height:"150px",background:"#fff"}}>
                            <div className="card" style={{border:"0px",height:"170px"}}>
                                <div className="card-body" id="counterCardBody">                            
                                    
                                        <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "black"}}>
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <p className="m-0p txt-danger-dark">Overestimated Outliers <span>/</span></p>
                                        <p className="m-5p txt-info-dark"> Underestimated Outliers</p>
                                        </div>
                                        </div>
                                    <span className="xtooltip" style={{color: "black"}}><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext fbold p-5p">Materials for which predicted consumption is less than the actual consumption</span>
                                        </span>
                                    
                                    <h4 className="txt-danger-dark f20 mt-10p">
                                        <span className="counter">
                                            <span onClick={this.onClickCounterShowData.bind(this,10)}>
                                            <CountUp end={this.state.outlier_excess_count}  delay={0} /></span>  <br />
                                            <span className="txt-info-dark"  onClick={this.onClickCounterShowData.bind(this,11)}><CountUp end={this.state.outlier_under_count} delay={0} /> </span>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
					
                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0" style={{height:"150px",background:"#fff"}}>
                            <div className="card" style={{border:"0px",height:"170px"}}>
                                <div className="card-body" id="counterCardBody">                            
                                
                                    
                                    <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "black"}} className="txt-success-dark">
                                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                        <p className="m-0p">Prediction</p>
                                        <p className="m-5p">Accuracy</p>
                                        </div>
                                    <span className="xtooltip" style={{color: "black"}}><i className="fa fa-info-circle"></i>
                                            <span className="xtooltiptext p-5p">Cost accuracy in the predicted horizon</span>
                                        </span>
                                    </div>
                                    <h4 className="txt-secondary-dark f20 mt-20p"><span className="counter"> <CountUp end={this.state.cost_accuracy} delay={0} /> %
									
									</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col cirlce-d cursorPointer">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">                            
                                    <span className="f13 countertxt">Under Predicted Inventory
                                        <span className="xtooltip">&nbsp;<i className="fa fa-info-circle"></i> 
                                            <span className="xtooltiptextR">Percentage of under predicted materials</span>
                                        </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <h4 className="txt-warning-dark f30"><span className="counter"><CountUp end={this.state.under_pred_percentage} delay={0} /> %</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   */}
                <div className="row mb-0p pt-10p" style={{ height: "170px", marginLeft: "3px", position: "relative" }}>
                    <div className="col-12 d-flex align-items-center pt-20p" style={{ backgroundColor: "#f9f9f9" }}>

                        <div className="col counter-card-plate " style={{ color: "purple" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">Total Predicted Materials</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 0, "purple")}><CountUp end={this.state.total_count} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip-new" style={{ color: "black", border: "3px solid purple" }}>
                                    <p>Materials Considered for classification Includes Spares -&gt; Vital, Essential and Desirable</p>
                                </div>
                            </div>

                        </div>

                        <div className="col counter-card-plate " style={{ color: "#809fff" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">AI - Sensitive <br /> Materials</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 1, "#809fff")}><CountUp end={this.state.po_count} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #809fff" }}>
                                    <p>Materials with &gt;= 10 consumptions in the history</p>
                                </div>
                            </div>
                        </div>

                        <div className="col counter-card-plate" style={{ color: "#00cccc" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">Non - AI Sensitive Materials</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 2, "#00cccc")}><CountUp end={this.state.non_ai_count} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #00cccc" }}>
                                    <p>Materials with &lt; 10 consumptions in the history</p>
                                </div>
                            </div>

                        </div>

                        <div className="col counter-card-plate" style={{ color: "#4793AF" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">Never Consumed Materials</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 3, "#4793AF")}><CountUp end={this.state.neverCount} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #4793AF" }}>
                                    <p>Materials with no consumption</p>
                                </div>
                            </div>

                        </div>

                        <div className="col counter-card-plate" style={{ color: "#03C04A" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">Exact Prediction Materials<br />( ETP )</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 4, "#03C04A")}><CountUp end={this.state.exact_count} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #03C04A" }}>
                                    <p>Materials for which AI/ML model's predicted quantity perfectly matches the actual quantity consumed or used. It represents an ideal scenario where the model's forecasts are spot on.</p>
                                </div>
                            </div>

                        </div>

                        <div className="col counter-card-plate " style={{ color: "#FF3535" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">Excess Prediction Materials<br />( ESP )</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 5, "#FF3535")}><CountUp end={this.state.excess_count} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #FF3535" }}>
                                    <p>Materials for which AI/ML model predicted a quantity that exceeds the actual consumed or used quantity. This overestimation might lead to excess inventory holding costs.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col counter-card-plate " style={{ color: "#fdc958" }} >
                            <h5 className="f14 fw-600 mb-10p text-center">Under Prediction Materials<br />( UP )</h5>
                            <p className="f35 fw-600 m-0p counter-pointer" title="Click here for full details" onClick={this.onClickCounterShowData.bind(this, 6, "#fdc958")}><CountUp end={this.state.under_count} delay={0} /></p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #fdc958" }}>
                                    <p>Materials for which AI/ML model's predicted quantity that is less than the actual quantity consumed or used. Under-predicting can lead to potential stockouts.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col counter-card-plate " style={{ color: "#ff8fa3" }}>
                            <h5 className="f14 fw-600 mb-10p text-center">Excess Cost<br />( $ )</h5>
                            <p className="f35 fw-600 m-0p"><CountUp end={this.state.excess_cost} delay={0} />K</p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid #ff8fa3" }}>
                                    <p>Represents the total financial impact or cost incurred due to over-predicting material needs. This could encompass costs associated with holding excess inventory.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col counter-card-plate" style={{ color: "blue" }}>
                            <h5 className="f14 fw-600 mb-10p text-center">Under Prediction Percentage</h5>
                            <p className="f35 fw-600 m-0p"><CountUp end={this.state.under_pred_percentage} delay={0} />%</p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid blue" }}>
                                    <p>This metric showcases the proportion of materials that were under-predicted by the model compared to the total predicted materials. It gives an idea of how often the model tends to underestimate demand.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col counter-card-plate" style={{ borderRight: "3px solid #f9f9f9", color: "green" }}>
                            <h5 className="f14 fw-600 mb-10p text-center">Model Accuracy Percentage</h5>
                            <p className="f35 fw-600 m-0p"><CountUp end={this.state.cost_accuracy} delay={0} />%</p>
                            <div className="info-icon">
                                <i className="fa fa-info-circle"></i>
                                <div className="info-tooltip" style={{ color: "black", border: "3px solid green" }}>
                                    <p>Represents how accurate the AI/ML-driven model is in its predictions, higher accuracy percentage indicates a more reliable and effective model.</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="row mb-20p mt-10p pt-10p beffect">
                    <div className="col-sm-5">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <PieChart
                                        series={this.state.pieSeries}
                                        title={"Prediction Results (Category Wise)"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-6">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody" style={{minHeight:"55vh"}}>
                                   <div className="col-sm-12 bfnt">
                                        Excess Inventory Cost Incurred:  
                                        <span> {this.state.excess_cost} ($)</span>
                                   </div>
                                   <div className="col-sm-12 bfnt">
                                        Under Predicted Inventory Count: <span>{this.state.under_pred_percentage} (%)</span>
                                   </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-sm-7">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    {/* <div className="filterSelectI">
                                        Material Type: 
                                        <Select
                                            value={this.state.predactvalue}
                                            options={[{value:'ALL',label:"All"},{value:"HC",label:"Critical"}]}
                                            onChange={this.onChangePredActValue.bind(this)}
                                        />
                                    </div> */}
                                    {/*<div className="filterSelectI">
                                        Prediction Category: 
                                        <Select
                                            value={this.state.predcatvalue}
                                            options={[{value:'ALL',label:"All"},{value:"Exact",label:"Exact"},{value:"Excess",label:"Excess"},{value:"Under",label:"Under"}]}
                                            onChange={this.onChangePredCategoryValue.bind(this)}
                                        />
                                </div>*/}
                                    <div className={"chartLoading " + (this.state.loadshow1)}>
                                        <div className="loaderpos">
                                            <img src={require("../../assets/images/loading.gif")} />
                                        </div>
                                    </div>
                                    <LineChart
                                        series={this.state.lineChartSeries}
                                        categories={this.state.lineCategories}
                                        title={"Consumption Forecast: Predicted vs Actual"}
                                        xaxistitle={"Total Quantity"}
                                        pointUnits={"$"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-sm-12 mt-40p">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <TimeSeriesQtyMaterialChart
                                        title={"Material Quantity Consumption: Trend and Forecast"}
                                        subtitle={""}
                                        apiOrderData={this.state.apiOrderData}
                                        predApiOrderData={this.state.predApiOrderData}
                                        drillDownData = {this.state.drillDownData}
                                    />
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 mt-40p">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <TimeSeriesCountMaterialChart
                                        title={"Material Count: Trend and Forecast"}
                                        subtitle={""}
                                        apiOrderData={this.state.apiOrderData}
                                        predApiOrderData={this.state.predApiOrderData}
                                        drillDownData = {this.state.drillDownData}
                                    />
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 mt-40p">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <TimeSeriesChartComponent
                                        title={"Consumption Maintenance Order"}
                                        subtitle={""}
                                        apiOrderData={this.state.apiOrderData}
                                        predApiOrderData={this.state.predApiOrderData}
                                        drillDownData = {this.state.drillDownData}
                                    />
                                
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="col-sm-12 mt-40p">
                        <div className="crm-numbers pb-0">
                            <div className="card">
                                <div className="card-body" id="counterCardBody">
                                    <SplineChartComponent
                                        title={"Prediction vs Actual Quantity Consumption"}
                                        // subtitle={"Note:- For most of the materials actual consumption is yet to be given"}
                                        drillDownData = {this.state.drillDownData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>


                <div className={"sliderBlock2 " + (this.state.showSlideBlock)}>
                    <h5 className="crd-bg p-10p" style={{ backgroundColor: `${this.state.gridStateBg}` }}>{this.state.gridState}</h5>
                    <div className="col-sm-12">
                        <div id="myGrid" style={{ width: "100%", height: "80vh", fontSize: "13px", fontWeight: "600" }} className="col-sm-12 ag-theme-balham dropdown">
                            <AgGridReact
                                rowData={this.state.rowData}
                                columnDefs={colDefs}
                                gridOptions={{ context: { componentParent: this } }}
                                defaultColDef={this.state.defaultColDef}
                                frameworkComponents={this.state.frameworkComponents}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                enableRangeSelection={true}
                            />
                        </div>
                    </div>
                </div>
                <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>

                <div className={"loader " + this.state.loadshow}></div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div>

            </div>
        )
    }
}

function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
$(function () {
    $(".datepicker").datepicker({
        dateFormat: "yy-mm-dd",
        changeYear: true,
        startDate: $("#startDate").val(),
        endDate: $("#endDate").val()
    });
});

function abbreviateNumber(number) {
    /*if (number >= 1e12) {
      return (number / 1e12).toFixed(2) + ' T';
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + ' B';
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + ' M';
    } else if (number >= 1e5) {
      return (number / 1e5).toFixed(2) + ' L';
    } else if (number >= 1e4) {
      return (number / 1e3).toFixed(2) + ' K';
    } else {
      return number.toString();
    }*/
    if (number >= 1e12) {
        return (number / 1e12).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' T';
    } else if (number >= 1e9) {
        return (number / 1e9).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' B';
    } else if (number >= 1e6) {
        return (number / 1e6).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' M';
    } /*else if (number >= 1e5) {
    return (number / 1e5).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' L';
  }*/ else if (number >= 1e5) {
        return (number / 1e35).toLocaleString('en-US', { maximumFractionDigits: 2 }) + ' K';
    } else {
        return number.toLocaleString('en-US');
    }

}

export default withRouter(OverallSummaryDashboard);
