import React, {Component} from 'react';
import './App.css';
import { Outlet, Link } from "react-router-dom";
import NavBar from './components/header/Navbar';
import Sidebar from './components/sidebar/Sidebar';
import Error from './components/errorComponent';

class App extends React.Component {
  state ={
    error: null, 
    errorInfo: null
  }
  componentDidCatch(error, errorInfo){
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }
  render() {
    if (this.state.errorInfo) {
      var err = this.state.error.toString();
      if(err.includes("cross-origin error") === true)
      {
        window.location.reload()
      }
      return (
         (err.includes("cross-origin error") === true)?"Loading ..."
         :
         <div id="wrapper">
          <div  className="col-md-4"></div>
          <div id="content-wrapper" className="col-md-4" style={{fontSize:"15px",lineHeight:"35px",backgroundColor:"#fdf4f4",color:"#000",padding:"30px"}}>
              <h2>Something went wrong.</h2>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
              
            </div>
            
          <div  className="col-md-4"></div>
        </div>
        );
     }
    return (
      
		// <div id="wrapper"> 
		
    //     <Sidebar />
		//   <div id="content-wrapper" className="d-flex flex-column">
			
    //         <div id="content">
		// 		        <NavBar />
    //             <Error >{this.props.children}</Error>
    //             <Outlet props={this.props.children} />
    //         </div>

    //     </div>
			
    //   </div>

      <div className="main_container">
      <div className="bgn bgi"></div>
        <div className="col-md-3 left_col" id="sidebar">
            <Sidebar />
        </div>
        <div className="top_nav" id="top-navbar">
          <NavBar />
        </div>

        <div className="right_col" role="main" style={{minHeight:"90vh"}}>
            <Error >{this.props.children}</Error>
              <Outlet props={this.props.children} />
        </div>
      </div>
    );
  }
}

export default App;
