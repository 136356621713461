import React, { Component, useState } from "react";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import CSVFileValidator from 'csv-file-validator';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
var moment = require("moment");


const height = 35;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}

const Mlist = (props) => {
    const options = props.options;
    const durationlist = props.durationlist
    const [material_code, setMaterialCode] = useState([]);
    const [show, setShow] = useState(false);
    const [basicTitle, setBasicTitle] = useState('');
    const [basicType, setBasicType] = useState('default');
    const [duration, setDuration] = useState(null);
    const [scenario_type, setScenario_type] = useState('');
    const [scenario_description, setScenario_description] = useState('');
    const [learning_type, setLearning_type] = useState(2)
    const [upload_type, setUpload_type] = useState(1)
    const [email, setEmail] = useState("")
    const [consumption_end_date, setConsumptionEndDate] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    const onChangeMaterial = (newValue) => {
        setMaterialCode(newValue);
    };
    const handleEmail = (e) => {
        console.log(e.target.value)
        setEmail(e.target.value);
    };

    const onChangeDuration = (newValue) => {
        setDuration(newValue);
    }

    const handleScenarioType = (e) => {
        setScenario_type(e.target.value)
    }

    const handleScenarioDescription = (e) => {
        setScenario_description(e.target.value)
    }

    const handleLearningType = (e) => {
        setLearning_type(e.target.value)
    }

    const handlerConsumptionEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var mm = d.getMonth() + 1;
        if (mm < 10) {
          var month = "0" + mm;
        } else {
          var month = mm;
        }
    
        var consumptionEndDate =
          d.getFullYear() +
          "-" +
          month +
          "-" +
          (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        // previousFromDate = from_date;
        setConsumptionEndDate(consumptionEndDate)
      };

    const handleUploadType = (e) => {
        setUpload_type(e.target.value)
        // console.log(e.target.value,'val')
        if (e.target.value == 1) {
            setMaterialCode([]);
            document.getElementById("inValidDataInfo").innerHTML = "";
            document.getElementById("bulkUploadBtn").type = "submit"
            document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
            document.getElementById("bulkUploadBtn").classList.add("btn-danger");
        }
    }

    const onSubmitGetData = async (e) => {
        e.preventDefault();
        setIsOpen(true)
        let check = 0;
        // console.log(material_code, "material_code-duration");
        if (material_code.length > 0) {
            if (upload_type == 1) {
                var materialCodes = material_code.map((mc) => mc.value);
                if (materialCodes.length > 0) {
                    if (materialCodes.indexOf("all") >= 0) {
                        var mcodes = [];
                        await options.map((on) => {
                            if (on.value != "all") {
                                mcodes.push(on.value)
                            }
                        });
                        var materialCodes = $.grep(mcodes, function (value) {
                            return value !== "all";
                        });
                    }
                    // console.log(materialCodes.length,"materialCodes-length")
                    check = 1;
                    const params = {
                        material_codes: materialCodes,
                        learning_type: 1,
                        scenario_type: scenario_type,
                        scenario_description: scenario_description,
                        email: email,
                        consumption_end_date: consumption_end_date
                    }
                    redirectURL.post('/forecast/storelearningdatarequest', params)
                        .then(response => {
                            setShow(true);
                            setBasicTitle(response.data.message);
                            if (response.data.status === "Success") {
                                setIsOpen(false)
                                setBasicType('success');
                            } else {
                                setIsOpen(false)
                                setBasicType('danger');
                            }
                        });
                }
            } else {
                check = 1;
                const params = {
                    material_codes: material_code,
                    learning_type: 1,
                    scenario_type: scenario_type,
                    scenario_description: scenario_description,
                    email: email,
                    consumption_end_date: consumption_end_date
                }
                console.log("paramsmenulist", params)
                redirectURL.post('/forecast/storelearningdatarequest', params)
                    .then(response => {
                        setShow(true);
                        setBasicTitle(response.data.message);
                        if (response.data.status === "Success") {
                            setIsOpen(false)
                            setBasicType('success');
                        } else {
                            setIsOpen(false)
                            setBasicType('danger');
                        }
                    });
            }
        }
        if (check === 0) {
            setShow(true);
            setIsOpen(false)
            setBasicTitle("Select the material to continue");
            setBasicType('warning');
        }
    }

    const closeAlert = () => {
        setShow(false);
        if (basicType == "success") {
            window.location.reload();
        }
    }
    const templatePath = require('../../assets/json/trigger_learning_material_data_template.csv');

    const changeCSVFileHandler = async (e) => {

        const config = {
            headers: [

                {
                    name: 'Material Code',
                    inputName: 'item_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }



        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                document.getElementById("inValidDataInfo").innerHTML = "";
                if (csvData.inValidData.length > 0) {
                    document.getElementById("bulkUploadBtn").type = "button"
                    document.getElementById("bulkUploadBtn").classList.remove("btn-danger");
                    document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
                    let invalidData = csvData.inValidData;
                    let element = document.getElementById("inValidDataInfo")
                    invalidData.map(item => {
                        let row, column
                        if (item.rowIndex == undefined) {
                            row = 'NA'
                        } else {
                            row = item.rowIndex
                        }
                        if (item.columnIndex == undefined) {
                            column = 'NA'
                        } else {
                            column = item.columnIndex
                        }

                        element.innerHTML += "Column : " + column + "," + " Row : " + row + "--" + item.message + "<br></br>"
                    })

                } else {
                    document.getElementById("bulkUploadBtn").type = "submit"
                    document.getElementById("bulkUploadBtn").classList.remove("btn-secondary");
                    document.getElementById("bulkUploadBtn").classList.add("btn-danger");

                    let uploadedData = csvData.data;
                    const valuesOfMat = uploadedData.map(obj => obj.item_code);
                    setMaterialCode(valuesOfMat)
                }
            })
            .catch(err => { })
    }
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: 'bold',
            color: "black" // Set font-weight to bold for selected options
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontWeight: 'bold', // Always display the selected option in bold
        }),
    };


    return (
        <form method="POST" onSubmit={onSubmitGetData} style={{color: "black"}}>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            />
            <div className="col-md-12">
                <div className="form-group ">
                    <label className="col-form-label f15 fbold">
                        <input type='radio' value={1} checked={upload_type == 1} onChange={handleUploadType} />&nbsp;&nbsp;Material Multi Select &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <label className="col-form-label f15 fbold">
                        <input type='radio' value={2} checked={upload_type == 2} onChange={handleUploadType} />&nbsp;&nbsp;Material Bulk Upload</label>
                    <br />

                    {upload_type == 1 ? (<label className="col-form-label f15 fbold">Material</label>) : (<label className="col-form-label f15 fbold">Upload File</label>)}
                    {upload_type == 1 ?
                        <Select
                            placeholder={"Select"}
                            isMulti={true}
                            onChange={onChangeMaterial}
                            name="material_code"
                            value={material_code}
                            components={{ MenuList }}
                            options={options}
                            styles={customStyles}
                        />
                        : ''}
                    {upload_type == 2 ?
                        <>
                            <div className="form-group col-sm-12 mb-20p">
                                <input type="file" name="csvfile" id="csvfile" className="form-control" onChange={changeCSVFileHandler} required />
                            </div>
                            <div className="form-group col-sm-12">
                                <a className="btn btn-warning float-right" href={templatePath} target="_blank">Download Sample Template</a>
                            </div>
                        </>
                        : ''}
                </div>

                <div className="form-group ">
                    <label className="col-form-label f15 fbold">Consumption End Date</label>
                    <Datetime
                        value={consumption_end_date}
                        disableCloseOnClickOutside={false}
                        timeFormat={false}
                        closeOnSelect={true}
                        inputProps={{
                            placeholder: "Start Date",
                            name: "start_date",
                            autoComplete: "off",
                        }}
                        dateFormat="YYYY-MM-DD"
                        onChange={handlerConsumptionEndDateTime}
                    />
                    {/* <label className="col-form-label f15 fbold">
                        <input type='radio' value={1} checked={learning_type == 1} onChange={handleLearningType} />&nbsp;&nbsp;Force Learning on all Materials &nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <label className="col-form-label f15 fbold">
                        <input type='radio' value={2} checked={learning_type == 2} onChange={handleLearningType} />&nbsp;&nbsp;Learning only on Materials with New Data</label> */}
                    <br />
                    <label className="col-form-label f15 fbold">Scenario Name</label>
                    <input type='text' className="form-inputbox forminp" value={scenario_type} onChange={handleScenarioType} />
                    <label className="col-form-label f15 fbold">Scenario Description</label>
                    <textarea rows='5' cols='55' value={scenario_description} className="form-inputbox forminp" onChange={handleScenarioDescription} />
                </div>
                <div className="form-group ">
                    <label className="col-form-label f15 fbold" >Email</label>
                    <input type="text" className="form-inputbox forminp" value={email} onChange={handleEmail} />
                </div>
            </div>
            {/* <div className="col-md-12">
                <div className="form-group ">
                    <label className="col-form-label f12">Prediction Horizon</label>
                    <Select
                        placeholder={"Select"}
                        onChange={onChangeDuration}
                        name="duration"
                        value={duration}
                        options={durationlist}
                    />
                </div>
            </div>     */}
            <div className="col-md-12">
                <div className="form-group ">
                    <button type="submit" id="bulkUploadBtn" className="btn btn-danger mt-30p">Start Learning</button>
                </div>
            </div>
            {isOpen && (
                    <>
                    <div className={"sliderbg"}></div>
                    
                            <div class="new-loader" style={{ zIndex: "15000" }}>
                                <div class="bar bar1"></div>
                                <div class="bar bar2"></div>
                                <div class="bar bar3"></div>
                                <div class="bar bar4"></div>
                                <div class="bar bar5"></div>
                                <div class="bar bar6"></div>
                                <div class="bar bar7"></div>
                                <div class="bar bar8"></div>
                            </div>
                            <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "42%", zIndex: "9999" }}>
                                <p>Loading...</p>
                            </div>
                            </>
                )}
            <div id="inValidDataInfo" style={{ color: "red" }}></div>
        </form>
    );
};

export default Mlist;
