import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import 'jquery-ui';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import SweetAlert from 'react-bootstrap-sweetalert';
import Constant from "../common/constants";
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import { getHyphenDDMMMYYYY } from '../common/utils';
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from '../redirectURL';
import GridButton from './gridButton';
import ViewItems from './viewItems';
import EditItem from "./editItem";
import CostSymbol from "./costSymbol"
import DeletePopup from './deletepopup';
var infoBox = require('../common/google-infowindow');
const scaleanimation = {
    transition: 'all 1s easy-out'
}

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];

export default class ForecastMaterials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            frameworkComponents: {
                GridButton: GridButton,
                ViewItems: ViewItems,
                EditItem: EditItem,
                CostSymbol: CostSymbol,
            },
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            detailCellRendererParams: {},
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 1000,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            showSlideBlockRoute: "sidebarcls",
            showSlideBlockUpload: "sidebarcls",
            showSlideBlockStack: "sidebarcls",
            showSlideEditForm: "",
            overlayBlock: "show-n",
            routeData: [],
            rfreshmap: 1,
            deliverpickupslist: [],
            dealers: [],
            plants: [],
            uploadfile: '',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            loadplan: [],
            allLoadPlan: [],
            deliverydates: [],
            deliveryopts: [],
            filterDeliveryDate: { "value": "", "label": "Select Delivery Date" },
            clusterlist: [],
            filtercluster: { "value": "", "label": "Select Cluster" },
            vehicletypelist: [],
            filterVehicleType: { "value": "", "label": "All" },
            mapcol: "show-n",
            gridocl: "col-sm-12",
            filter_date: "",
            vtypediv: "show-n",
            vsupplierdiv: "show-n",
            selectedCluster: "",
            fdealerlist: [],
            filterdealer: { "value": "", "label": "All" },
            showloader: "show-n",
            mpnode: [],
            mpfor: "",
            clusterpoints: [],
            nmapcluster: "show-n",
            nmap: "show-m",
            scluster: "",
            stackContent: "",
            stackImage: "",
            stacktbl: [],
            stacktblbottom: [],
            stackOriginalData: [],
            showrunprocessbtn: "show-m",
            showanimate: "show-n",
            locationNames: [],
            loadFile: '',
            material_configurations: 0,
            location: { label: "Select Location", value: "" },
            loadshow: 'show-m',
            overly: 'show-m',
            processErrMsg: "",
            processErrShow: "",
            transactionId: "",
            droppedList: [],
            rowid: "",
            item_code: "",
            item_description: "",
            cost_in_USD_per_item: "",
            isOpen: false,
            selectedRowData: null,
            material_type: "",
            material_classification: "",
            exchange_rate: 0
        }

    }
    async componentDidMount() {
        let url_type = window.location.href
        let path_type = url_type.split('?')
        let data_type
        let path = path_type[path_type.length - 1]

        if (path == 'inbound') {
            data_type = 1
        } else {
            data_type = 2
        }
        await this.setState({ data_type: data_type })
        let params = {
            data_type: data_type
        }
        await getToolConfiguration(params)
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
        var material_configurations = 0;
        if (toolConfig != undefined && toolConfig != "") {
            if (toolConfig.length > 0) {
                material_configurations = toolConfig[0].material_configuration;
            }
        }
        this.setState({
            material_configurations: material_configurations
        });
        await this.boundTypeLocations(params)
        await this.loadMaterials()
    }

    loadMaterials = () => {
        this.setState({ loadshow: 'show-m', overlayBlock: "show-m" })
        var params = {

        }
        redirectURL.post('/forecast/getDataFromMaterialsMasterScreen', params)
            .then(response => {
                var records = response.data;
                var newRecords = records.map((each) => (
                    { ...each, popupText: "Double click to see" }
                ))
                this.setState({
                    rowData: newRecords,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n"
                })
            })
    }

    boundTypeLocations = async (param) => {

        let locationNames = this.state.locationNames;
        await redirectURL.post('master/getBoundTypeLocations', param)
            .then(async (response) => {
                if (response.data.status == 'success') {
                    let boundLocations = response.data.boundLocations
                    if (boundLocations.length > 0) {
                        boundLocations.map(item => {
                            locationNames.push({
                                value: item.location_code,
                                label: item.location_name
                            })
                        })
                        await this.setState({
                            locationNames: locationNames,
                            location: locationNames[0]
                        })
                    }
                }
            })

    }

    closeAlert = () => {
        if (this.state.basicType === "success") {
            this.setState({
                show: false
            });
            window.location.reload()
        }
        else {
            this.setState({
                show: false
            });
        }
    }

    onClickShowUpload = () => {
        this.setState({
            showSlideBlockUpload: "slide25",
            overlayBlock: "show-m"
        })
    }

    hideSlideBlock = () => {
        this.setState({
            overlayBlock: "show-n",
            showSlideBlock: "",
            showSlideBlockUpload: "",
            showSlideBlockRoute: "",
            showSlideEditForm: "",
            showSlideBlockStack: "",
            loadFile: '',
            loadshow: 'show-n',
            overly: 'show-n',
        })
        // $("#uploadFile").val("");
        $("#notify_email").val("");
        document.getElementById("uploadFile").value = ""
    }
    fixProcess = () => {
        this.setState({
            processErrMsg: "",
            processErrShow: 0,
            loadFile: ''
        });
        $("#uploadFile").val("");
        $("#notify_email").val("");
        //document.getElementById("upform").reset();
    }
    ignoreProcessError = () => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        });
        var transactionId = this.state.transactionId;
        // console.log("transactionId",transactionId)
        var params = {
            transactionId: transactionId,
            data_type: this.state.data_type,
        }
        redirectURL.post("dispatch/generateLoadByTransactionId", params).then((response) => {
            //console.log(response.data,"response")
            var statusCode = response.data.status;
            if (statusCode == 0 || statusCode == 1) {
                this.setState({
                    basicTitle: response.data.message,
                    basicType: "success",
                    show: true,
                    processErrMsg: "",
                    processErrShow: 0,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n",
                    showSlideBlockUpload: "",
                });
            }
            else {
                this.setState({
                    processErrMsg: response.data.message,
                    processErrShow: 1,
                    transactionId: response.data.transactionId,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n",
                });
            }
            //window.location.reload();
        });
    }

    changeFileHandler = async (e) => {
        // console.log(e);
        this.setState({
            loadFile: e.target.files[0]
        });

        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if (check == true) {
            if (e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))

            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }

    }

    uploadBulkFormHandler(event) {
        event.preventDefault();
        var flag = 0;
        let saveData = new FormData();
        if (flag == 0) {
            saveData.append("file", this.state.loadFile);

            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
            });
            redirectURL.post("/forecast/readXLSData", saveData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': "*",
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, Content-Type'
                }
            }).then((response) => {
                if (response.data.records !== undefined) {
                    var uploadxlsdata = response.data.records;
                    console.log("uploadxlsdata ", uploadxlsdata)

                    const requiredProperties = ["Material Code", "Material Description", "Material Cost ( $ )", "Material Classification", "Material Type"];

                    var missingProperties = []
                    const allObjectsHaveProperties = uploadxlsdata.every((record, index) => {
                        const missingProps = requiredProperties.filter(property => !record.hasOwnProperty(property));

                        if (missingProps.length > 0) {
                            missingProperties.push({ index, missingProps });
                        }

                        return missingProps.length === 0;
                    });
                    console.log(allObjectsHaveProperties, "chusukunta")

                    if (uploadxlsdata.length > 0 && allObjectsHaveProperties) {
                        var p = {
                            xlsdata: uploadxlsdata,
                            email: localStorage.getItem("email")
                        }
                        // console.log("p ", p)
                        redirectURL.post("/forecast/saveBulkMaterials", p)
                            .then((resp) => {
                                //   console.log(resp.data)
                                if (resp.data.message == "success") {
                                    this.setState({
                                        uploadfile: "",
                                        showSlideBlockUpload: "",
                                        overlayBlock: "show-n",
                                        showloader: "show-n",
                                        loadshow: 'show-n',
                                        show: true,
                                        basicTitle: "Data Successfully Uploaded",
                                        basicType: "success",
                                        overly: 'show-n',
                                    });
                                    this.loadMaterials()
                                    document.getElementById("uploadFile").value = ""
                                }
                                else {
                                    this.setState({
                                        show: true,
                                        basicTitle: "Failed to upload data",
                                        basicType: "danger",
                                        showloader: "show-n"
                                    });
                                }
                            })
                    } else {
                        this.setState({
                            basicTitle: `In your Excel file, at line ${missingProperties[0].index + 2}, the ${missingProperties[0].missingProps} column is empty, which is required for processing.`,
                            basicType: "warning",
                            show: true,
                            loadshow: 'show-n',
                            overly: 'show-n',
                        })
                    }
                }
                else {
                    this.setState({
                        basicTitle: "Please upload the file with Data",
                        basicType: "warning",
                        show: true,
                        loadshow: 'show-n',
                        overly: 'show-n',
                    })
                }
            });
        }
        else {
            this.setState({
                basicTitle: "Please upload the file",
                basicType: "warning",
                show: true,
            })
        }
    };

    checkTransactionStatus = (transactionId) => {
        var query = {
            transaction_id: transactionId
        }
        redirectURL.post("/dispatch/checkTransactionStatus", query).then((response) => {
            // console.log(response,"responseFromcheckTransactionStatus")
            var statusCode = response.data.status;
            if (statusCode == 8) {
                this.setState({
                    processErrMsg: response.data.message,
                    processErrShow: 1,
                    transactionId: response.data.transactionId,
                    droppedList: response.data.droppedList,
                    statusCode: statusCode,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n",
                });
            }
            else if (statusCode > 3 && statusCode != 8) {
                this.setState({
                    processErrMsg: response.data.message,
                    processErrShow: 1,
                    transactionId: response.data.transactionId,
                    statusCode: statusCode,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n",
                });
            }
            else if (statusCode == 0) {
                var checkCount = this.state.checkCount;
                var updatedCnt = parseInt(checkCount) + 1;
                this.setState({
                    checkCount: updatedCnt
                });
                // console.log(updatedCnt,"checkCount")
                if (updatedCnt < 3) {
                    this.chkStatus(response.data.transactionId);
                }
                else {
                    this.setState({
                        basicTitle: "File is processing",
                        basicType: "success",
                        show: true,
                        loadshow: 'show-n',
                        overly: 'show-n',
                        overlayBlock: "show-n",
                        showSlideBlockUpload: "",
                        loadFile: ''
                    })
                    $("#uploadFile").val("");
                    $("#notify_email").val("");
                }
            }
            else {
                this.setState({
                    basicTitle: response.data.message,
                    basicType: "success",
                    show: true,
                    loadshow: 'show-n',
                    overly: 'show-n',
                    overlayBlock: "show-n",
                    showSlideBlockUpload: "",
                });
                this.getDispatchPlanData()
            }
        });
    }

    chkStatus = (transactionId) => {
        this.checkTransactionStatus(transactionId);
    }

    viewPlanDetails = async (propdata) => {
        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        window.location.href = '/viewdispatchplandetails?' + encryptedstring;
    }


    locationChanged = async (location) => {
        await this.setState({
            location: location
        })
        await this.getDispatchPlanData()
    }

    onClickViewItems(rownode) {
        // console.log("rownode ", rownode);
        this.setState({

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: "Supplier Code",
                            field: "supplier_code",
                            editable: false,
                            width: 160
                        },
                        {
                            headerName: "Supplier Name",
                            field: "supplier_name",
                            editable: false,
                            width: 200
                        },
                        {
                            headerName: "Service Location",
                            field: "service_location",
                            editable: false,
                            width: 300
                        },
                        {
                            headerName: "Lead Time (Days)",
                            field: "lead_time",
                            editable: false,
                            width: 300
                        },

                    ],
                    defaultColDef: {
                        // flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                        initialWidth: 200,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        sortable: true
                    },
                    context: this,
                    overlayNoRowsTemplate: 'No rows to show',

                    // components: { datePicker: getDatePicker() },
                },
                getDetailRowData: async function (param) {
                    try {
                        param.successCallback([]);
                        var rowitems = param.data.suppliers;
                        param.successCallback(rowitems);

                    } catch (error) {

                    }

                },
                masterDetail: true
            }
        });
        // console.log(propsdata);
        if (rownode.column.colDef.field == 'viewitems') {

            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else {

            rownode.node.setExpanded(false);
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

    };
    onGridReady1 = (params) => {
        this.gridApiNew = params.api;
        this.gridColumnApiNew = params.columnApi;

    };
    onClickEditForm(rownode) {
        //console.log("rownode ", rownode)
        this.setState({
            showSlideEditForm: "slide25",
            overlayBlock: "show-m",
            item_code: rownode.data.item_code,
            item_description: rownode.data.item_description,
            cost_in_USD_per_item: rownode.data.cost_in_USD_per_item,
            rowid: rownode.data._id,
            material_type: rownode.data.material_type,
            material_classification: rownode.data.material_classification,
            exchange_rate: rownode.data.exchange_rate
        })
    }
    /*onClickCostSymbol(rownode){
       var params = {
           item_code: rownode.data.item_code
       }
       redirectURL.post("/forecast/getCostDetails",params)
       .then((response) => {
           console.log(response)
           if(response.status === 200)
           {
             console.log(response.data)
           }
           else{
               console.log("error")
           }
       })
   }*/


    async onClickCostSymbol(row) {
        console.log(row, "okaey")
        this.setState({ detailCellRendererParams: {} });
        var params = {
            item_code: row.data.item_code
        }
        var costRowData = []
        var costcolsDefs = [
            {
                headerName: "Cost ( $ )",
                field: "cost",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Exchange Rate ( ₹ )",
                field: "exchange_rate",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Start Date",
                field: "start_date",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.start_date !== "" && params.data.start_date !== undefined) {
                            return getHyphenDDMMMYYYY(params.data.start_date);
                        }
                    } catch (error) {

                    }
                }
            },
            {
                headerName: "End Date",
                field: "end_date",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                valueGetter: function (params) {
                    try {
                        if (params.data.end_date !== "" && params.data.end_date !== undefined) {
                            return getHyphenDDMMMYYYY(params.data.end_date);
                        }
                    } catch (error) {

                    }
                }
            }
        ]
        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: costcolsDefs,
                    overlayNoRowsTemplate: 'No rows to show',
                    height: 200,

                },
                getDetailRowData: async function (param) {
                    param.successCallback([]);
                    await redirectURL.post("/forecast/getCostDetails", params)
                        .then((response) => {
                            if (response.status === 200) {
                                //console.log(response.data.result[0].costs)
                                param.successCallback(response.data.result[0].costs);
                            }
                            else {
                                console.log("error")
                            }
                        })
                },
                masterDetail: true
            }
        });
        if (row.colDef.field == "cost_in_USD_per_item") {
            row.node.setExpanded(!row.node.expanded);
        }
        else {
            row.node.setExpanded(false);
        }
    }


    updateMaterialData(event) {
        event.preventDefault();
        var params = {
            rowid: this.state.rowid,
            item_code: this.state.item_code,
            item_description: this.state.item_description,
            cost_in_USD_per_item: this.state.cost_in_USD_per_item,
            material_type: this.state.material_type,
            material_classification: this.state.material_classification,
            exchange_rate: this.state.exchange_rate
        }
        redirectURL.post("/forecast/updateMaterialData", params)
            .then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully update item",
                        basicType: "success",
                        item_code: "",
                        item_description: "",
                        cost_in_USD_per_item: "",
                        showSlideEditForm: "",
                        overlayBlock: "show-n",

                    })
                    this.loadMaterials();
                }
                else {
                    this.setState({
                        show: true,
                        basicTitle: "Failed to update item",
                        basicType: "danger"
                    })
                }
            })
    }
    changeHandler(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    renderDeleteButton = params => {
        return (
            <div>
                <button
                    type="button"
                    onClick={() => this.handleDeleteClick(params.data)}
                    className="btn btn-danger grid-btn"
                    style={{ border: "0px" }}
                >
                    <i className="fa fa-trash"></i> Delete
                </button>
            </div>
        );
    };

    handleDeleteClick = rowData => {
        this.setState({
            isOpen: true,
            selectedRowData: rowData,
        });
    };
    handleClosePopup = () => {
        this.setState({
            isOpen: false,
            selectedRowData: null,
        });
    };

    handleDelete = async (rowData) => {
        var params = {
            item_code: rowData.item_code,
            item_description: rowData.item_description,
            deletion_performed_by: localStorage.getItem("email"),
            deleted_time: new Date().toISOString()
        }
        await redirectURL.post("/forecast/performMaterialDeletion", params)
            .then((response) => {
                console.log(response)
                this.setState({ isOpen: false })
                if (response.data.status === "success") {
                    this.setState({
                        show: true,
                        basicTitle: "Successfully deleted",
                        basicType: "success",
                    })
                }
            })
            .catch((error) => {
                if (response.data.status === "failed") {
                    this.setState({
                        show: true,
                        basicTitle: "Failed to delete Material",
                        basicType: "danger",
                    })
                }
            })
    }


    render() {
        var columnwithDefs = [
            {
                headerName: "Edit Material",
                field: "edititem",
                width: 140,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: "EditItem"
                    };
                    return rendComponent

                },
            },
            {
                headerName: "Delete Material",
                field: "delete",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"],
                cellRendererFramework: this.renderDeleteButton,
            },
            /*
              {
                headerName: "Material Price History",
                field: "costsymbol",
                width: 150,
                params: { onClickFunction: this.onClickCostSymbol, iconName: "fa fa-dollar", btnClass: "btn btn-info" },
                cellRendererSelector: function (params) {

                    var rendComponent = {
                        component: "CostSymbol"
                    };
                    return rendComponent

                },
            }
            */
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Cost ( $ )",
                field: "cost_in_USD_per_item",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                tooltipField: "popupText",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Exchange Rate ( ₹ )",
                field: "exchange_rate",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            }
        ]
        var newColumWithDefs = [
            {
                headerName: "Material Code",
                field: "item_code",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

            {
                headerName: "Material Type",
                field: "material_type",
                width: 150,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },

            {
                headerName: "Material Classification",
                field: "material_classification",
                width: 190,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Description",
                field: "item_description",
                width: 250,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Material Cost ( $ )",
                field: "cost_in_USD_per_item",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                tooltipField: "popupText",
                headerClass: ["column-header-style"]
            },
            {
                headerName: "Exchange Rate ( ₹ )",
                field: "exchange_rate",
                width: 170,
                filter: true,
                resizable: true,
                editable: false,
                headerClass: ["column-header-style"]
            }
        ]
        var templatePath = require('../../assets/json/materials_master_data_template.csv');
        console.log(this.state.isOpen, "isOpen")
        var checkColumWithDefs = localStorage.getItem("role") == "admin" ? columnwithDefs : newColumWithDefs
        return (
            <div class="container-fluid">
                <div className={"animatenow " + (this.state.showanimate)} style={{ textAlign: "center" }}>
                    <span className="pp-h">Processing Plan. Please wait.....</span>
                    <img src={require("../../assets/images/r.gif")} style={{ width: "85vh" }} />
                </div>
                <div className={'lodr ' + (this.state.showloader)}>
                    <div className="loaderdiv">
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                        <div className="loaderwave"></div>
                    </div>
                    <div className="ldrtext">Loading....</div>
                </div>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div class="row mb-20p">
                    <div className="col-sm-12">
                        <h5 className='fbold f23' style={{ color: "black" }}>
                            Material Master
                            {localStorage.getItem("role") == "admin" && (<button type="button" className="btn btn-success float-right font-white" onClick={this.onClickShowUpload}>Upload Material Master</button>)}
                        </h5>
                    </div>
                </div>

                <div class="row mb-20p">
                    <div className={"mxheit col-sm-12 "} >
                        <div id="myGrid1" style={{ width: "100%", height: "70vh", fontSize: "13px", fontWeight: "500" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                // modules={this.state.modules}
                                columnDefs={checkColumWithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this, buttons: { viewItemBtn: "Suppliers" } },
                                    rowClass: 'your-row-class', // Set the row class for styling

                                }}
                                // components={this.state.components}
                                // onCellDoubleClicked={this.onClickShowClusterRouteOnMap.bind(this)}
                                enableRangeSelection={true}
                                masterDetail={true}
                                detailCellRendererParams={this.state.detailCellRendererParams}
                                onCellDoubleClicked={this.onClickCostSymbol.bind(this)}
                                enableBrowserTooltips={true}
                                suppressRowHoverHighlight={true}

                            />
                            <DeletePopup
                                isOpen={this.state.isOpen}
                                onCancel={this.handleClosePopup}
                                onDelete={this.handleDelete}
                                rowData={this.state.selectedRowData}
                            />
                        </div>
                    </div>

                </div>
                <div className={"overlay-block " + (this.state.overlayBlock)} onClick={this.hideSlideBlock}></div>
                <div className={"sliderBlock2 " + (this.state.showSlideBlockUpload)}>
                    <h5 className="crd-bg p-10p ">Upload Input File</h5>
                    <div className="row">
                        <div className="col-sm-12">
                            {this.state.processErrShow == 0 ?
                                <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                                    <div className="row p-20p">
                                        <div className="form-group col-sm-12">
                                            <a className="btn btn-warning" href={templatePath} target="_blank">Download Sample Template</a>
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <label className="fw-600 f15" style={{ color: "black" }}>Upload File</label>
                                            <input type="file" name="uploadFile" id="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
                                        </div>
                                        <div className="form-group col-sm-12 mb-20p">
                                            <button type="submit" className="btn btn-info">SAVE</button>
                                            <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                        </div>
                                    </div>
                                </form>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
                <div className={"sliderBlock2 " + (this.state.showSlideEditForm)}>
                    <h5 className="crd-bg p-10p">Edit Data - {this.state.item_code}</h5>
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={this.updateMaterialData.bind(this)}>
                                <div className="row p-20p">
                                    {/* <div className="form-group col-sm-12 mb-20p">
                                        <label style={{ color: "#000" }}>Material Code</label>
                                        <input type="text" name="item_code" id="item_code" value={this.state.item_code} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                    </div> */}
                                    <div className="form-group col-sm-12 mb-20p fw-600 f17">
                                        <label style={{ color: "#000" }}>Material Description</label>
                                        <input type="text" name="item_description" id="item_description" value={this.state.item_description} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p fw-600 f17">
                                        <label style={{ color: "#000" }}>Material Cost</label>
                                        <input type="text" name="cost_in_USD_per_item" id="cost_in_USD_per_item" value={this.state.cost_in_USD_per_item} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p fw-600 f17">
                                        <label style={{ color: "#000" }}>Material Type</label>
                                        <input type="text" name="material_type" id="cost_in_USD_per_item" value={this.state.material_type} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p fw-600 f17">
                                        <label style={{ color: "#000" }}>Material Classification</label>
                                        <input type="text" name="material_classification" id="cost_in_USD_per_item" value={this.state.material_classification} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p fw-600 f17">
                                        <button type="submit" className="btn btn-info">SAVE</button>
                                        <button type="button" className="btn btn-danger" onClick={this.hideSlideBlock.bind(this)}>CANCEL</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
                {/* <div className={"loader " + this.state.loadshow} style={{zIndex: "9999"}}></div> */}
                {this.state.loadshow === "show-m" && (
                    <>
                        <div class="new-loader" style={{ zIndex: "9999" }}>
                            <div class="bar bar1"></div>
                            <div class="bar bar2"></div>
                            <div class="bar bar3"></div>
                            <div class="bar bar4"></div>
                            <div class="bar bar5"></div>
                            <div class="bar bar6"></div>
                            <div class="bar bar7"></div>
                            <div class="bar bar8"></div>
                        </div>
                        <div className="col-12 fw-600 f20" style={{ color: "black", position: "absolute", top: "54%", left: "47%", zIndex: "9999" }}>
                            <p>Loading...</p>
                        </div>
                    </>
                )}
                {/* <div className={"overlay-part " + (this.state.overly)} onClick={this.hideSlideBlock.bind(this)}></div> */}
            </div>
        )
    }
}


function arrayMin(arr) {
    return arr.reduce(function (p, v) {
        return (p < v ? p : v);
    });
}

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}